<template>
  <a-drawer title="修复任务" width="35%" :visible="open" @close="onClose">
    <a-form-model
      ref="form"
      :model="form"
      :rules="rules"
      :label-col="{ span: 8 }"
      :wrapper-col="{ span: 16 }"
    >
      <a-form-model-item label="办理人" prop="delayHandleUser">
        <a-input v-model="form.delayHandleUser" placeholder="请输入" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="办理日期" prop="delayHandleTime">
        <a-date-picker
          v-model="form.delayHandleTime"
          show-time
          type="date"
          placeholder="请输入"
          style="width: 100%;"
          value-format="YYYY-MM-DD HH:mm:ss"
          format="YYYY-MM-DD HH:mm:ss"
        />
      </a-form-model-item>
      <a-form-model-item label="延时修复原因" prop="delayReason">
        <a-textarea v-model="form.delayReason" placeholder="请输入" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="预计维修日期" prop="delayExpectedRepairTime">
        <a-date-picker
          v-model="form.delayExpectedRepairTime"
          show-time
          type="date"
          placeholder="请输入"
          style="width: 100%;"
          value-format="YYYY-MM-DD HH:mm:ss"
          format="YYYY-MM-DD HH:mm:ss"
        />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getRepairDetail, updateRepairDetail } from '@/api/business/repairDetail'

export default {
  name: 'DelayForm',
  props: {},
  components: {},
  data() {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        delayHandleUser: null,
        delayHandleTime: null,
        delayReason: null,
        delayExpectedRepairTime: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        delayHandleUser: [
          { required: true, message: '办理人不能为空', trigger: 'blur' }
        ],
        delayHandleTime: [
          { required: true, message: '办理日期不能为空', trigger: 'change' }
        ],
        delayReason: [
          { required: true, message: '延时修复原因不能为空', trigger: 'blur' }
        ],
        delayExpectedRepairTime: [
          { required: true, message: '预计维修日期不能为空', trigger: 'change' }
        ]
      }
    }
  },
  filters: {},
  created() {
  },
  computed: {},
  watch: {},
  mounted() {
  },
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        delayHandleUser: null,
        delayHandleTime: null,
        delayReason: null,
        delayExpectedRepairTime: null
      }
      this.$nextTick(() => {
        this.$refs.form && this.$refs.form.resetFields()
      })
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset()
      const id = row.id
      getRepairDetail({ 'id': id }).then(response => {
        this.form = response.data
        this.open = true
      })
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs.form.validate(valid => {
        if (!valid) {
          return false
        }
        this.submitLoading = true
        this.form.status = 2
        // this.form.delayHandleTime = parseTime(this.form.delayHandleTime)
        // this.form.delayExpectedRepairTime = parseTime(this.form.delayExpectedRepairTime)
        updateRepairDetail(this.form).then(response => {
          this.$message.success(
            '修改成功',
            3
          )
          this.open = false
          this.$emit('ok')
        }).finally(() => {
          this.submitLoading = false
        })
      })
    }
  }
}
</script>
