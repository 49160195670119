import request from '@/utils/request'


// 查询维修明细管理列表
export function listRepairDetail(query) {
  return request({
    url: '/business/repair-detail/list',
    method: 'get',
    params: query
  })
}

// 查询维修明细管理分页
export function pageRepairDetail(query) {
  return request({
    url: '/business/repair-detail/page',
    method: 'get',
    params: query
  })
}

// 查询维修明细管理详细
export function getRepairDetail(data) {
  return request({
    url: '/business/repair-detail/detail',
    method: 'get',
    params: data
  })
}

// 新增维修明细管理
export function addRepairDetail(data) {
  return request({
    url: '/business/repair-detail/add',
    method: 'post',
    data: data
  })
}

// 修改维修明细管理
export function updateRepairDetail(data) {
  return request({
    url: '/business/repair-detail/edit',
    method: 'post',
    data: data
  })
}

// 删除维修明细管理
export function delRepairDetail(data) {
  return request({
    url: '/business/repair-detail/delete',
    method: 'post',
    data: data
  })
}

// 回传检测数据
export function importInspectData(data) {
  return request({
    url: '/business/repair-detail/importInspectData',
    method: 'post',
    data: data
  })
}

// 回传复检数据
export function importReInspectData(data) {
  return request({
    url: '/business/repair-detail/importReInspectData',
    method: 'post',
    data: data
  })
}