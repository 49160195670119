import request from '@/utils/request'


// 查询维修管理列表
export function listRepairTask(query) {
  return request({
    url: '/business/repair-task/list',
    method: 'get',
    params: query
  })
}

// 查询维修管理分页
export function pageRepairTask(query) {
  return request({
    url: '/business/repair-task/page',
    method: 'get',
    params: query
  })
}

// 查询维修管理详细
export function getRepairTask(data) {
  return request({
    url: '/business/repair-task/detail',
    method: 'get',
    params: data
  })
}

// 新增维修管理
export function addRepairTask(data) {
  return request({
    url: '/business/repair-task/add',
    method: 'post',
    data: data
  })
}

// 修改维修管理
export function updateRepairTask(data) {
  return request({
    url: '/business/repair-task/edit',
    method: 'post',
    data: data
  })
}

// 删除维修管理
export function delRepairTask(data) {
  return request({
    url: '/business/repair-task/delete',
    method: 'post',
    data: data
  })
}
