<template>
  <div id="userLayout" :class="['user-layout-wrapper', isMobile && 'mobile']">
    <div class="container">
      <img :src="bgImage" alt="" class="bg-image">
      <div class="form">
        <div class="title">
          <div class="text">欢迎来到质检采集平台</div>
        </div>
        <a-divider orientation="left" style="color: #fafafa;">密码登录</a-divider>
        <div class="content">
          <router-view />
        </div>
      </div>

      <div class="footer">
        <div class="copyright">copyright 2024 all rights reserved 呈鑫质检信息采集管理端 豫icp备14022279号</div>
      </div>
    </div>
  </div>
</template>

<script>
import { deviceMixin } from '@/store/device-mixin'

export default {
  name: 'UserLayout',
  mixins: [deviceMixin],
  data() {
    return {
      bgImage: undefined
    }
  },
  mounted() {
    document.body.classList.add('userLayout')
    this.getDefaultBg()
  },
  beforeDestroy() {
    document.body.classList.remove('userLayout')
  },
  methods: {
    getDefaultBg() {
      const that = this
    }
  }
}
</script>

<style lang="less" scoped>
#userLayout.user-layout-wrapper {
  height: 100%;

  .bg-image {
    width: 100%;
    height: 100%;
    //width: 1920px;
    //height: 1080px;
    position: absolute;
    left: 0;
    top: 0;
  }

  &.mobile {
    .container {
      .main {
        max-width: 368px;
        width: 98%;
      }
    }
  }

  .container {
    width: 100%;
    min-height: 100%;
    background: #f0f2f5 url(~@/assets/bg.png) no-repeat;
    background-size: 100% 100%;
    padding: 0;
    //padding: 110px 0 144px;
    position: relative;
    z-index: 1;

    a {
      text-decoration: none;
    }

    .top {
      text-align: center;

      .header {
        height: 44px;
        line-height: 44px;

        .badge {
          position: absolute;
          display: inline-block;
          line-height: 1;
          vertical-align: middle;
          margin-left: -12px;
          margin-top: -10px;
          opacity: 0.8;
        }

        .logo {
          height: 44px;
          vertical-align: top;
          margin-right: 16px;
          border-style: none;
        }

        .title {
          font-size: 33px;
          color: rgba(0, 0, 0, .85);
          font-family: Avenir, 'Helvetica Neue', Arial, Helvetica, sans-serif;
          font-weight: 600;
          position: relative;
          top: 2px;
        }
      }

      .desc {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.45);
        margin-top: 12px;
        margin-bottom: 40px;
      }
    }

    .main {
      //min-width: 260px;
      width: 100%;
      //width: 368px;
      margin: 0 auto;
    }

    .footer {
      position: absolute;
      width: 100%;
      bottom: 0;
      padding: 0 16px;
      margin: 48px 0 24px;
      text-align: center;

      .copyright {
        font-weight: 400;
        font-size: 16px;
        color: #fff;
        line-height: 19px;
        mix-blend-mode: difference;
      }
    }

    .form {
      position: fixed;
      right: 32%;
      bottom: 20%;
      width: 600px;
      /* background: #fff; */
      border-radius: 16px;
      -webkit-box-shadow: 0 0 99px 1px rgba(20, 122, 242, .2);
      box-shadow: 0 0 99px 1px rgba(20, 122, 242, .2);

      .title {
        //width: calc(100% - 80px);
        padding: 40px 40px 24px 40px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        position: relative;

        .text {
          font-weight: bold;
          font-size: 32px;
          color: #ffffff;
          line-height: 22px;
          height: 31px;
          width: 100%;
          text-align: center;
        }
      }

      .content {
        //width: calc(100% - 80px);
        padding: 48px 40px;
      }
    }
  }
}
</style>
