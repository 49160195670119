import request from '@/utils/request'


// 查询法规文件管理列表
export function listRegulatoryDocument(query) {
  return request({
    url: '/business/regulatory-document/list',
    method: 'get',
    params: query
  })
}

// 查询法规文件管理分页
export function pageRegulatoryDocument(query) {
  return request({
    url: '/business/regulatory-document/page',
    method: 'get',
    params: query
  })
}

// 查询法规文件管理详细
export function getRegulatoryDocument(data) {
  return request({
    url: '/business/regulatory-document/detail',
    method: 'get',
    params: data
  })
}

// 新增法规文件管理
export function addRegulatoryDocument(data) {
  return request({
    url: '/business/regulatory-document/add',
    method: 'post',
    data: data
  })
}

// 修改法规文件管理
export function updateRegulatoryDocument(data) {
  return request({
    url: '/business/regulatory-document/edit',
    method: 'post',
    data: data
  })
}

// 删除法规文件管理
export function delRegulatoryDocument(data) {
  return request({
    url: '/business/regulatory-document/delete',
    method: 'post',
    data: data
  })
}
