import request from '@/utils/request'


// 查询组件分类字典列表
export function listComponentDict(query) {
  return request({
    url: '/business/component-dict/list',
    method: 'get',
    params: query
  })
}

// 查询组件分类字典分页
export function pageComponentDict(query) {
  return request({
    url: '/business/component-dict/page',
    method: 'get',
    params: query
  })
}

// 查询组件分类字典详细
export function getComponentDict(data) {
  return request({
    url: '/business/component-dict/detail',
    method: 'get',
    params: data
  })
}

// 新增组件分类字典
export function addComponentDict(data) {
  return request({
    url: '/business/component-dict/add',
    method: 'post',
    data: data
  })
}

// 修改组件分类字典
export function updateComponentDict(data) {
  return request({
    url: '/business/component-dict/edit',
    method: 'post',
    data: data
  })
}

// 删除组件分类字典
export function delComponentDict(data) {
  return request({
    url: '/business/component-dict/delete',
    method: 'post',
    data: data
  })
}
