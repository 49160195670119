<template>
  <!--  <page-header-wrapper>-->
  <a-card :bordered="false">
    <a-row :gutter="24">
      <a-col :span="24">
        <!-- 条件搜索 -->
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-row :gutter="48">
              <a-col :md="6" :sm="24">
                <a-form-item label="姓名">
                  <a-input v-model="queryParam.nickName" placeholder="请输入" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="手机号">
                  <a-input v-model="queryParam.phonenumber" placeholder="请输入" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-button type="primary" @click="handleQuery">
                  <a-icon type="search" />
                  查询
                </a-button>
                <a-button style="margin-left: 8px" @click="resetQuery">
                  <a-icon type="redo" />
                  重置
                </a-button>
              </a-col>
            </a-row>
          </a-form>
        </div>
        <!-- 操作 -->
        <div class="table-operations">
          <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['system:user:add']">
            <a-icon type="plus" />
            新增
          </a-button>
        </div>
        <!-- 增加修改 -->
        <!-- 创建/编辑用户,单独封装了组件 -->
        <create-form ref="createForm" :deptOptions="deptOptions" :statusOptions="dict.type['sys_normal_disable']"
                     :sexOptions="dict.type['sys_user_sex']" @ok="getList" @select-tree="getTreeselect" />
        <!-- 数据展示 -->
        <a-table :loading="loading" :size="tableSize" rowKey="userId" :columns="columns" :data-source="list"
                 :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :pagination="false"
                 :bordered="tableBordered">
          <!-- tips: 此处的status 其实是后端的enable字段，所以status '0' 代表的是开启  status '1' 代表的是关闭 -->
          <span slot="createTime" slot-scope="text, record">{{ parseTime(record.createTime) }}</span>
          <span slot="sex" slot-scope="text, record"><dict-tag :options="dict.type['sys_user_sex']" :value="record.sex" /></span>
          <span slot="operation" slot-scope="text, record" v-if="record.userId !== 1">
            <a-space>
              <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['system:user:edit']">编辑</a>
              <a @click="handleDelete(record)" v-hasPermi="['system:user:remove']">删除</a>
            </a-space>
          </span>
        </a-table>
        <!-- 分页 -->
        <a-pagination class="ant-table-pagination" show-size-changer show-quick-jumper :current="queryParam.pageNum"
                      :total="total" :page-size="queryParam.pageSize" :showTotal="total => `共 ${total} 条`"
                      @showSizeChange="onShowSizeChange" @change="changeSize" />
      </a-col>
    </a-row>
  </a-card>
  <!--  </page-header-wrapper>-->
</template>

<script>

import { changeUserStatus, delUser, deptTreeSelect, listUser } from '@/api/system/user'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import constants from '@/utils/constants'
import { mapGetters } from 'vuex'

export default {
  name: 'User',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  dicts: ['sys_normal_disable', 'sys_user_sex'],
  computed: {
    ...mapGetters(['isEnterpriseOrStaff', 'enterpriseId', 'deptId'])
  },
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 部门树选项
      deptOptions: [{
        id: 0,
        label: '',
        children: []
      }],
      // 日期范围
      dateRange: [],
      queryParam: {
        pageNum: 1,
        pageSize: 10,
        nickName: undefined,
        phonenumber: undefined,
        status: undefined,
        deptId: constants.staffDeptId
      },
      columns: [
        {
          title: '姓名',
          dataIndex: 'nickName',
          align: 'center'
        },
        {
          title: '手机号',
          dataIndex: 'phonenumber',
          align: 'center'
        },
        {
          title: '性别',
          dataIndex: 'sex',
          scopedSlots: { customRender: 'sex' },
          align: 'center'
        },
        {
          title: '最后一次登录时间',
          dataIndex: 'loginDate',
          align: 'center'
        },
        {
          title: '创建人',
          dataIndex: 'creator',
          align: 'center'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {},
  created() {
    this.getList()
    this.getDeptTree()
  },
  watch: {},
  methods: {
    getTreeselect() {
    },
    /** 查询用户列表 */
    getList() {
      this.loading = true
      if (this.isEnterpriseOrStaff) {
        this.queryParam.enterpriseId = this.enterpriseId
      }
      listUser(this.addDateRange(this.queryParam, this.dateRange)).then(response => {
          this.list = response.data.records
          this.total = response.data.total
          this.loading = false
        }
      )
    },
    /** 查询部门下拉树结构 */
    getDeptTree() {
      deptTreeSelect().then(response => {
        this.deptOptions = response.data
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.dateRange = []
      this.queryParam = {
        pageNum: 1,
        pageSize: 10,
        nickName: undefined,
        phonenumber: undefined,
        status: undefined,
        deptId: constants.staffDeptId
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.userId)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    clickDeptNode(deptId) {
      this.queryParam.deptId = deptId
      this.handleQuery()
    },
    /* 用户状态修改 status 0开启 1关闭*/
    confirmHandleStatus(row) {
      const oldStatus = row.status
      const text = row.status === '1' ? '启用' : '关闭'
      const status = row.status === '1' ? '0' : '1'
      changeUserStatus(row.userId, status).then((res) => {
        if (res.success) {
          row.status = row.status === '0' ? '1' : '0'
          this.$message.success(text + '成功', 3)
        } else {
          row.status = oldStatus
          this.$message.error(`${text}失败, ${res.message}`, 3)
        }
      }).catch(() => {
        this.$message.error(text + '异常', 3)
      })
    },
    cancelHandleStatus(row) {
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const userIds = row.userId || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + userIds + '的数据',
        onOk() {
          return delUser(userIds)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
            })
        },
        onCancel() {
        }
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          that.download('system/user/export', {
            ...that.queryParam
          }, `user_${new Date().getTime()}.xlsx`)
        },
        onCancel() {
        }
      })
    }
  }
}
</script>
