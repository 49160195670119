<template>
  <!-- 增加修改 -->
  <a-drawer :title="formTitle" width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose" :destroyOnClose="true">
    <a-form-model ref="form" :model="form"  :rules="rules">
      <a-form-model-item label="企业编码" prop="userId" v-if="form.userId">
        <a-input :value="`QY${form.userId}`" placeholder="" :disabled="true" />
      </a-form-model-item>
      <a-form-model-item label="企业名称" prop="nickName">
        <a-input v-model="form.nickName" placeholder="请输入" :maxLength="30" />
      </a-form-model-item>
      <a-form-model-item label="企业联系人" prop="contact">
        <a-input v-model="form.contact" placeholder="请输入" />
      </a-form-model-item>
      <a-form-model-item label="联系方式" prop="phonenumber">
        <a-input v-model="form.phonenumber" placeholder="请输入" />
      </a-form-model-item>
      <a-form-model-item label="后台账户" prop="userName">
        <a-input v-model="form.userName" placeholder="请输入" :disabled="form.userId" />
      </a-form-model-item>
      <a-form-model-item label="创建人" prop="creator" v-if="form.userId">
        <a-input v-model="form.creator" placeholder="" :disabled="true" />
      </a-form-model-item>
      <a-form-model-item label="创建时间" prop="createTime" v-if="form.userId">
        <a-input v-model="form.createTime" placeholder="" :disabled="true" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>

import { getUser, addUser, updateUser } from '@/api/system/user'
import constants from '@/utils/constants'

export default {
  name: 'CreateForm',
  props: {},
  components: {},
  data() {
    return {
      submitLoading: false,
      replaceFields: { children: 'children', title: 'label', key: 'id', value: 'id' },
      // 默认密码
      initPassword: undefined,
      formTitle: '',
      // 表单参数
      form: {
        userId: undefined,
        deptId: constants.enterpriseDeptId,
        userName: undefined,
        nickName: undefined,
        password: undefined,
        phonenumber: undefined,
        email: undefined,
        sex: '3',
        status: '0',
        remark: undefined,
        postIds: [],
        roleIds: [constants.enterpriseRoleId]
      },
      open: false,
      rules: {
        nickName: [
          { required: true, message: '企业名称不能为空', trigger: 'blur' }
        ],
        contact: [
          { required: true, message: '企业联系人不能为空', trigger: 'blur' }
        ],
        phonenumber: [
          { required: true, message: '联系方式不能为空', trigger: 'blur' },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: '请正确填写手机号',
            trigger: 'blur'
          }
        ],
        userName: [
          { required: true, message: '后台账号不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {},
  created() {
    this.getConfigKey('sys.user.initPassword').then(response => {
      this.initPassword = response.data
      this.form.password = response.data
    })
  },
  computed: {},
  watch: {},
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.form = {
        userId: undefined,
        deptId: constants.enterpriseDeptId,
        userName: undefined,
        nickName: undefined,
        password: undefined,
        phonenumber: undefined,
        email: undefined,
        sex: '3',
        status: '0',
        remark: undefined,
        postIds: [],
        roleIds: [constants.enterpriseRoleId]
      }
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset()
      this.$emit('select-tree')
      this.open = true
      this.formTitle = '新增子公司'
      this.form.password = this.initPassword
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      const userId = row ? row.userId : ids
      getUser(userId).then(response => {
        this.form = response.data.data
        this.open = true
        this.formTitle = '编辑子公司'
      })
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.userId !== undefined) {
            updateUser(this.form).then(response => {
              this.$message.success(
                '编辑成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addUser(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
