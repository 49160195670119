import request from '@/utils/request'

// 查询台账管理列表
export function listLedger(query) {
  return request({
    url: '/business/ledger/list',
    method: 'get',
    params: query
  })
}

// 查询台账管理分页
export function pageLedger(query) {
  return request({
    url: '/business/ledger/page',
    method: 'get',
    params: query
  })
}

// 查询台账管理详细
export function getLedger(data) {
  return request({
    url: '/business/ledger/detail',
    method: 'get',
    params: data
  })
}

// 新增台账管理
export function addLedger(data) {
  return request({
    url: '/business/ledger/add',
    method: 'post',
    data: data
  })
}

// 修改台账管理
export function updateLedger(data) {
  return request({
    url: '/business/ledger/edit',
    method: 'post',
    data: data
  })
}

// 删除台账管理
export function delLedger(data) {
  return request({
    url: '/business/ledger/delete',
    method: 'post',
    data: data
  })
}

export function fakeDelLedger(data) {
  return request({
    url: '/business/ledger/fakeDelete',
    method: 'post',
    data: data
  })
}

export function reFakeDelLedger(data) {
  return request({
    url: '/business/ledger/reFakeDelete',
    method: 'post',
    data: data
  })
}

// 导入台账
export function importData(data) {
  return request({
    url: '/business/ledger/importData',
    method: 'post',
    data: data
  })
}
