<template>
  <a-drawer title="组件子类型" width="65%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <sub-component :componentId="componentId" />
    <div class="bottom-control">
      <a-space>
        <a-button type="dashed" @click="cancel">
          取消
        </a-button>
      </a-space>
    </div>
  </a-drawer>
</template>

<script>
import SubComponent from '@/views/business/subComponent/index.vue'

export default {
  name: 'SubComponentDrawer',
  props: {},
  components: { SubComponent },
  data() {
    return {
      open: false,
      componentId: undefined
    }
  },
  filters: {},
  created() {
  },
  computed: {},
  watch: {},
  mounted() {
  },
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
    },
    handleOpen(record) {
      this.open = true
      this.componentId = record.id
    }
  }
}
</script>
