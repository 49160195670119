<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24" v-if="!isEnterpriseOrStaff">
              <a-form-item label="企业名称" prop="enterpriseId">
                <a-select placeholder="请选择" v-model="queryParam.enterpriseId" style="width: 100%" allow-clear>
                  <a-select-option v-for="(d, index) in enterpriseList" :key="index" :value="d.key">{{
                      d.value
                    }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="运行设备" prop="runningEquipment">
                <a-input v-model="queryParam.runningEquipment" placeholder="请输入" allow-clear />
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="装置编码-区域" prop="deviceCode">
                <a-input v-model="queryParam.deviceCode" placeholder="请输入" allow-clear />
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="标签" prop="label">
                <a-input v-model="queryParam.label" placeholder="请输入" allow-clear />
              </a-form-item>
            </a-col>
            <a-col :md="!advanced && 4 || 24" :sm="24">
              <span class="table-page-search-submitButtons"
                    :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.importExcel.importExcelHandleOpen()"
                  v-hasPermi="['business:ledger:add']">
          导入
        </a-button>
        <a-button @click="handleExport" v-hasPermi="['business:ledger:export']">
          导出
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['business:ledger:remove']">
          删除
        </a-button>
        <a-button @click="handleExportTemplate" v-hasPermi="['business:ledger:export']">
          下载模版
        </a-button>
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 上传文件 -->
      <import-excel ref="importExcel" @ok="getList" />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange , fixed: true}"
        :pagination="false"
        :bordered="tableBordered"
        :scroll="{ x: true }"
      >
        <div slot="imageCode" slot-scope="text, record">
          <image-code-column :image-code="record.imageCode" />
        </div>
        <span slot="enterpriseId" slot-scope="text, record">
          <select-tag :options="enterpriseList" :value="record.enterpriseId" />
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-space>
            <a @click="handleDelete(record)" v-hasPermi="['business:ledger:remove']" style="color: red;" v-if="!record.deleted">删除</a>
            <a @click="handleReDelete(record)" v-hasPermi="['business:ledger:remove']" style="color: green;" v-else>恢复</a>
          </a-space>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { delLedger, fakeDelLedger, pageLedger, reFakeDelLedger } from '@/api/business/ledger'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import { enterpriseNameList } from '@/api/system/user'
import SelectTag from '@/components/SelectTag/index.vue'
import ImportExcel from './modules/ImportExcel.vue'
import { mapGetters } from 'vuex'
import ImageCodeColumn from '@/components/ImageCodeColumn/index.vue'

export default {
  name: 'Ledger',
  components: {
    ImageCodeColumn,
    ImportExcel,
    SelectTag,
    CreateForm
  },
  mixins: [tableMixin],
  computed: {
    ...mapGetters(['isEnterpriseOrStaff', 'enterpriseId', 'deptId'])
  },
  data() {
    return {
      enterpriseList: [],
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        enterpriseId: undefined,
        deviceName: null,
        deviceCode: null,
        runningEquipment: null,
        productFlow: null,
        materialStatus: null,
        mainGinseng: null,
        label: null,
        direction: null,
        distance: null,
        floor: null,
        height: null,
        heatPreservation: null,
        size: null,
        detectMinimumDwellTime: null,
        describe: null,
        componentType: null,
        componentSubtype: null,
        extensionNumber: null,
        groupCode: null,
        leakageThreshold: null,
        testingAccessories: null,
        difficultDetection: null,
        difficultDetectionReason: null,
        imageCode: null,
        drawingNo: null,
        changeManagementId: null,
        annualOperatingTime: null,
        putOnRecordTime: null,
        startTime: null,
        riskDetection: null,
        riskDetectionReason: null,
        sealingMaterial: null,
        indoor: null,
        deleted: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '企业名称',
          dataIndex: 'enterpriseId',
          scopedSlots: { customRender: 'enterpriseId' },
          align: 'center',
          fixed: 'left',
          width: 200
        },
        {
          title: '装置名称',
          dataIndex: 'deviceName',
          ellipsis: true,
          align: 'center',
          width: 200
        },
        {
          title: '装置编码-区域',
          dataIndex: 'deviceCode',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '运行设备',
          dataIndex: 'runningEquipment',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '产品流',
          dataIndex: 'productFlow',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '物料状态',
          dataIndex: 'materialStatus',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '主参',
          dataIndex: 'mainGinseng',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '标签',
          dataIndex: 'label',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '方向',
          dataIndex: 'direction',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '距离',
          dataIndex: 'distance',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '楼层',
          dataIndex: 'floor',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '高度（m）',
          dataIndex: 'height',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '保温',
          dataIndex: 'heatPreservation',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '尺寸',
          dataIndex: 'size',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '检测最小停留时间',
          dataIndex: 'detectMinimumDwellTime',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '描述',
          dataIndex: 'describe',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '组件类型',
          dataIndex: 'componentType',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '组件子类型',
          dataIndex: 'componentSubtype',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '扩展号',
          dataIndex: 'extensionNumber',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '群组编码',
          dataIndex: 'groupCode',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '泄漏阈值',
          dataIndex: 'leakageThreshold',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '检测配件',
          dataIndex: 'testingAccessories',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '难于检测',
          dataIndex: 'difficultDetection',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '难于检测原因',
          dataIndex: 'difficultDetectionReason',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '图片编码',
          dataIndex: 'imageCode',
          ellipsis: true,
          align: 'center',
          scopedSlots: { customRender: 'imageCode' }
        },
        {
          title: '图号',
          dataIndex: 'drawingNo',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '变更管理ID',
          dataIndex: 'changeManagementId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '年运行时间/小时',
          dataIndex: 'annualOperatingTime',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '建档时间',
          dataIndex: 'putOnRecordTime',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '开工时间',
          dataIndex: 'startTime',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '险于检测',
          dataIndex: 'riskDetection',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '险于检测原因',
          dataIndex: 'riskDetectionReason',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '密封材质',
          dataIndex: 'sealingMaterial',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '室内',
          dataIndex: 'indoor',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '插入时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
          align: 'center',
          fixed: 'right',
          width: 100
        }
      ]
    }
  },
  filters: {},
  created() {
    this.getList()
    enterpriseNameList().then(res => {
      this.enterpriseList = res.data
    })
  },
  watch: {},
  methods: {
    /** 查询台账管理列表 */
    getList() {
      this.loading = true
      if (this.isEnterpriseOrStaff) {
        this.queryParam.enterpriseId = this.enterpriseId
        this.queryParam.deleted = false
      }
      pageLedger(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        enterpriseId: undefined,
        deviceName: undefined,
        deviceCode: undefined,
        runningEquipment: undefined,
        productFlow: undefined,
        materialStatus: undefined,
        mainGinseng: undefined,
        label: undefined,
        direction: undefined,
        distance: undefined,
        floor: undefined,
        heatPreservation: undefined,
        size: undefined,
        detectMinimumDwellTime: undefined,
        describe: undefined,
        componentType: undefined,
        componentSubtype: undefined,
        extensionNumber: undefined,
        groupCode: undefined,
        leakageThreshold: undefined,
        testingAccessories: undefined,
        difficultDetection: undefined,
        difficultDetectionReason: undefined,
        imageCode: undefined,
        drawingNo: undefined,
        changeManagementId: undefined,
        annualOperatingTime: undefined,
        putOnRecordTime: undefined,
        startTime: undefined,
        riskDetection: undefined,
        riskDetectionReason: undefined,
        sealingMaterial: undefined,
        indoor: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          const data = []
          that.ids.map(id => data.push({ 'id': id }))
          return fakeDelLedger(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
            })
        },
        onCancel() {
        }
      })
    },
    handleReDelete(row) {
      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认恢复所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          const data = []
          that.ids.map(id => data.push({ 'id': id }))
          return reFakeDelLedger(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '恢复成功',
                3
              )
            })
        },
        onCancel() {
        }
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          that.download('business/ledger/export', {
            ...that.queryParam
          }, `台账管理_${new Date().getTime()}.xlsx`)
        },
        onCancel() {
        }
      })
    },
    handleExportTemplate() {
      this.download('business/ledger/exportTemplate', {}, `台账模版.xlsx`)
    }
  }
}
</script>
