<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form"  :rules="rules">
      <a-form-model-item label="文件名称" prop="name">
        <a-input v-model="form.name" placeholder="请输入文件名称" />
      </a-form-model-item>
      <a-form-model-item label="文件url" prop="url">
        <file-upload v-model="form.url" type="file" :limit-size="10"></file-upload>
      </a-form-model-item>
      <a-form-model-item label="创建人" prop="creator" v-if="form.id">
        <a-input v-model="form.creator" :disabled="true" />
      </a-form-model-item>
      <a-form-model-item label="创建时间" prop="createTime" v-if="form.id">
        <a-input v-model="form.createTime" :disabled="true" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getRegulatoryDocument, addRegulatoryDocument, updateRegulatoryDocument } from '@/api/business/regulatoryDocument'
import { mapGetters } from 'vuex'

export default {
  name: 'CreateForm',
  props: {},
  components: {},
  computed: {
    ...mapGetters(['isEnterpriseOrStaff', 'enterpriseId', 'deptId'])
  },
  data() {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        name: null,
        url: null,
        createTime: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        name: [
          { required: true, message: '文件名称不能为空', trigger: 'blur' }
        ],
        url: [
          { required: true, message: '文件不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {},
  created() {
  },
  watch: {},
  mounted() {
  },
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        name: null,
        url: null,
        createTime: null
      }
      this.$nextTick(() => {
        this.$refs.form && this.$refs.form.resetFields()
      })
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getRegulatoryDocument({ 'id': id }).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.isEnterpriseOrStaff) {
            this.form.enterpriseId = this.enterpriseId
          }
          if (this.form.id !== undefined && this.form.id !== null) {
            updateRegulatoryDocument(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addRegulatoryDocument(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
