import request from '@/utils/request'

// 查询用户信息列表
export function listProject(query) {
  return request({
    url: '/system/diy/project/list',
    method: 'get',
    params: query
  })
}

// 查询用户信息详细
export function getProject(id) {
  return request({
    url: '/system/diy/project/' + id,
    method: 'get'
  })
}

// 新增用户信息
export function addProject(data) {
  return request({
    url: '/system/diy/project',
    method: 'post',
    data: data
  })
}

// 修改用户信息
export function updateProject(data) {
  return request({
    url: '/system/diy/project',
    method: 'put',
    data: data
  })
}

// 删除用户信息
export function delProject(id) {
  return request({
    url: '/system/diy/project/' + id,
    method: 'delete'
  })
}