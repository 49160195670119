import request from '@/utils/request'

const userApi = {
  Login: '/login',
  getImageCode: '/get-pic-captcha',
  Logout: '/logout',
  Register: '/register',
  // get my info
  UserInfo: '/get-user-info'
}

export function login(username, password, smsCode, uuid, userType, loginType) {
  const data = {
    username,
    password,
    validCode: smsCode,
    validCodeReqNo: uuid,
    userType,
    loginType,
    device: 'PC'
  }
  return request({
    url: userApi.Login,
    method: 'post',
    data: data
  })
}

// 注册方法
export function register(data) {
  return request({
    url: userApi.Register,
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

export function getInfo() {
  return request({
    url: userApi.UserInfo,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function logout() {
  return request({
    url: userApi.Logout,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

// 获取验证码
export function getCodeImg() {
  return request({
    url: userApi.getImageCode,
    method: 'get'
  })
}
