<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 蒙版，使用v-if指令根据showModal的值控制显示隐藏 -->
      <div  v-if="showModal" class="modal-overlay">
        <p>数据下载中，可能需要几分钟，请您耐心等待！确定下载完成后，再点击按钮关闭此页面...</p>
        <button style="margin-left: 30px;margin-top: -10px;" @click="closeOverlay">关闭</button>
      </div>
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24" v-if="!isEnterpriseOrStaff">
              <a-form-item label="企业名称" prop="enterpriseId">
                <a-select placeholder="请选择" v-model="queryParam.enterpriseId" style="width: 100%" allow-clear>
                  <a-select-option v-for="(d, index) in enterpriseList" :key="index" :value="d.key">{{ d.value }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="季度" prop="quarter">
                <a-select placeholder="请选择" v-model="queryParam.quarter" style="width: 100%" allow-clear>
                  <a-select-option :value="1">1季度</a-select-option>
                  <a-select-option :value="2">2季度</a-select-option>
                  <a-select-option :value="3">3季度</a-select-option>
                  <a-select-option :value="4">4季度</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="密封点分类" prop="sealingPointId">
                <a-select placeholder="请选择" v-model="queryParam.sealingPointId" style="width: 100%" allow-clear>
                  <a-select-option v-for="(d, index) in sealingPointDictList" :key="index" :value="d.key">{{ d.value }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="!advanced && 6 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['business:repair/task:add']">
          创建任务
        </a-button>
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
        :enterprise-list="enterpriseList"
        :sealing-point-dict-list="sealingPointDictList"
      />
      <task-detail-drawer
        ref="taskDetailDrawer"
        @delete="getList"
      />
      <import-inspect-excel
        ref="importInspectExcel"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
        :scroll="{ x: true }"
      >
        <span slot="enterpriseId" slot-scope="text, record">
          <select-tag :options="enterpriseList" :value="record.enterpriseId" />
        </span>
        <span slot="sealingPointId" slot-scope="text, record">
          <select-tag :options="sealingPointDictList" :value="record.sealingPointId" />
        </span>
        <span slot="quarter" slot-scope="text, record">
          <span>{{ record.quarter }}季度</span>
        </span>
        <span slot="type" slot-scope="text, record">
          <span v-if="record.type === 1">普通检测</span>
          <span v-if="record.type === 2">追踪检测</span>
        </span>
        <span slot="status" slot-scope="text, record">
          <span v-if="record.status === 0">未完成</span>
          <span v-if="record.status === 1">已完成</span>
        </span>
        <span slot="unInspectionCount" slot-scope="text, record">
          <span>{{ record.total - record.inspectionCount }}</span>
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-space>
            <a @click="exportDetailList(record, undefined)" v-hasPermi="['business:repair/task:edit']">下载数据</a>
            <a @click="$refs.importInspectExcel.importExcelHandleOpen()">回传数据</a>
            <a @click="$refs.taskDetailDrawer.openDrawer(record)">任务明细</a>
            <a @click="handleDelete(record)" v-hasPermi="['business:repair/task:remove']">删除</a>
          </a-space>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>

  </page-header-wrapper>
</template>
<script>
import { delRepairTask, pageRepairTask } from '@/api/business/repairTask'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import { enterpriseNameList } from '@/api/system/user'
import { selectListSealingPointDict } from '@/api/business/sealingPointDict'
import SelectTag from '@/components/SelectTag/index.vue'
import TaskDetailDrawer from '@/views/business/repairTask/modules/TaskDetailDrawer.vue'
import ImportInspectExcel from '@/views/business/repairTask/modules/ImportInspectExcel.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'RepairTask',
  components: {
    ImportInspectExcel,
    TaskDetailDrawer,
    SelectTag,
    CreateForm
  },
  mixins: [tableMixin],
  computed: {
    ...mapGetters(['isEnterpriseOrStaff', 'enterpriseId', 'deptId'])
  },
  data() {
    return {
      enterpriseList: [],
      sealingPointDictList: [],
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 控制蒙版显示隐藏的数据
      showModal: false,
      // 查询参数
      queryParam: {
        enterpriseId: undefined,
        quarter: undefined,
        sealingPointId: undefined,
        type: null,
        status: null,
        total: null,
        inspectionCount: null,
        leakageCount: null,
        deleted: false,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '企业名称',
          dataIndex: 'enterpriseId',
          scopedSlots: { customRender: 'enterpriseId' },
          align: 'center'
        },
        {
          title: '检测类型',
          dataIndex: 'type',
          scopedSlots: { customRender: 'type' },
          align: 'center'
        },
        {
          title: '检测状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          align: 'center'
        },
        {
          title: '季度',
          dataIndex: 'quarter',
          scopedSlots: { customRender: 'quarter' },
          align: 'center'
        },
        {
          title: '密封点分类',
          dataIndex: 'sealingPointId',
          scopedSlots: { customRender: 'sealingPointId' },
          align: 'center'
        },
        {
          title: '总检测点位',
          dataIndex: 'total',
          align: 'center'
        },
        {
          title: '已检测点位',
          dataIndex: 'inspectionCount',
          align: 'center'
        },
        {
          title: '未检测点位',
          dataIndex: 'unInspectionCount',
          scopedSlots: { customRender: 'unInspectionCount' },
          align: 'center'
        },
        {
          title: '泄漏点位',
          dataIndex: 'leakageCount',
          align: 'center'
        },
        {
          title: '创建人',
          dataIndex: 'creator',
          align: 'center'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          width: '180',
          scopedSlots: { customRender: 'createTime' },
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '300',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {},
  created() {
    this.getList()
    enterpriseNameList().then(res => {
      this.enterpriseList = res.data
    })
    selectListSealingPointDict().then(res => {
      this.sealingPointDictList = res.data
    })
  },
  watch: {},
  methods: {
    /** 查询维修管理列表 */
    getList() {
      this.loading = true
      if (this.isEnterpriseOrStaff) {
        this.queryParam.enterpriseId = this.enterpriseId
      }
      pageRepairTask(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        enterpriseId: undefined,
        quarter: undefined,
        sealingPointId: undefined,
        type: undefined,
        status: undefined,
        total: undefined,
        inspectionCount: undefined,
        leakageCount: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          const data = []
          that.ids.map(id => data.push({ 'id': id }))
          return delRepairTask(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
            })
        },
        onCancel() {
        }
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          that.download('business/repair/task/export', {
            ...that.queryParam
          }, `维修管理_${new Date().getTime()}.xlsx`)
        },
        onCancel() {
        }
      })
    },
    exportDetailList(row) {
      this.showModal = true
      setTimeout(() => {
        // 使用setTimeout实现休眠1秒,防止同时请求，浏览器做拦截
        this.download(`business/repair-detail/exportDetailList/${row.id}`, {}, `检测明细.xlsx`)
      }, 1000)
      this.download(`business/repair-detail/exportDetailImageList/${row.id}`, {}, `检测明细图片.zip`)
    },
    closeOverlay(){
      this.showModal = false
    }
  }
}
</script>
<style>
  /* 蒙版的样式，可以根据需要调整 */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* 半透明黑色背景 */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* 确保蒙版在其他内容之上 */
  }

  .modal-overlay p {
    color: white;
  }
</style>