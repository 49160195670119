import request from '@/utils/request'

// 查询子组件类型列表
export function listSubComponent(query) {
  return request({
    url: '/business/sub-component/list',
    method: 'get',
    params: query
  })
}

// 查询子组件类型分页
export function pageSubComponent(query) {
  return request({
    url: '/business/sub-component/page',
    method: 'get',
    params: query
  })
}

// 查询子组件类型详细
export function getSubComponent(data) {
  return request({
    url: '/business/sub-component/detail',
    method: 'get',
    params: data
  })
}

// 新增子组件类型
export function addSubComponent(data) {
  return request({
    url: '/business/sub-component/add',
    method: 'post',
    data: data
  })
}

// 修改子组件类型
export function updateSubComponent(data) {
  return request({
    url: '/business/sub-component/edit',
    method: 'post',
    data: data
  })
}

// 删除子组件类型
export function delSubComponent(data) {
  return request({
    url: '/business/sub-component/delete',
    method: 'post',
    data: data
  })
}
