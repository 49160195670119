<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form2" :model="form" :rules="rules">
      <a-form-model-item label="子组件分类名称" prop="name">
        <a-input v-model="form.name" placeholder="请输入子组件分类名称" />
      </a-form-model-item>
      <a-form-model-item label="对应字母" prop="letter">
        <a-input v-model="form.letter" placeholder="请输入对应字母" />
      </a-form-model-item>
      <a-form-model-item label="创建人" prop="creator" v-if="form.id">
        <a-input v-model="form.creator" :disabled="true" />
      </a-form-model-item>
      <a-form-model-item label="创建时间" prop="createTime" v-if="form.id">
        <a-input v-model="form.createTime" :disabled="true" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { addSubComponent, getSubComponent, updateSubComponent } from '@/api/business/subComponent'

export default {
  name: 'CreateForm2',
  props: {
    componentId: {
      type: String,
      required: true
    }
  },
  components: {},
  data() {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        name: null,
        letter: null,
        componentId: null,
        createTime: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        name: [
          { required: true, message: '组件子类型名称不能为空', trigger: 'blur' }
        ],
        letter: [
          { required: true, message: '对应字母不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {},
  created() {
  },
  computed: {},
  watch: {},
  mounted() {
  },
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.reset()
      this.open = false
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        name: null,
        letter: null,
        componentId: undefined,
        createTime: undefined
      }
      this.$nextTick(() => {
        this.$refs.form2 && this.$refs.form2.resetFields()
      })
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
      setTimeout(() => {
        this.form = Object.assign({}, {
          id: null,
          name: null,
          letter: null,
          componentId: undefined,
          createTime: undefined
        })
        this.$forceUpdate()
      }, 100)
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getSubComponent({ 'id': id }).then(response => {
        this.open = true
        this.formTitle = '修改'
        this.form = response.data
      })
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs.form2.validate(valid => {
        if (valid) {
          this.form.componentId = this.componentId
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateSubComponent(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addSubComponent(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
