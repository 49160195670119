<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="5" :sm="24" v-if="!isEnterpriseOrStaff">
              <a-form-item label="企业名称" prop="enterpriseId">
                <a-select placeholder="请选择" v-model="queryParam.enterpriseId" style="width: 100%" allow-clear>
                  <a-select-option v-for="(d, index) in enterpriseList" :key="index" :value="d.key">{{ d.value }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="5" :sm="24">
              <a-form-item label="所属装置" prop="deviceName">
                <a-select placeholder="请选择" v-model="queryParam.deviceName" style="width: 100%" allow-clear>
                  <a-select-option v-for="(d, index) in deviceList" :key="index" :value="d.value">{{ d.value }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="5" :sm="24">
              <a-form-item label="运行设备名称" prop="name">
                <a-input v-model="queryParam.name" placeholder="请输入" allow-clear />
              </a-form-item>
            </a-col>
            <a-col :md="5" :sm="24">
              <a-form-item label="运行设备编号" prop="code">
                <a-input v-model="queryParam.code" placeholder="请输入" allow-clear />
              </a-form-item>
            </a-col>
            <a-col :md="!advanced && 4 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['business:working/device:add']">
          <a-icon type="plus" />
          新增
        </a-button>
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
        :enterpriseList="enterpriseList"
        :deviceList="deviceList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="enterpriseId" slot-scope="text, record"><select-tag :options="enterpriseList" :value="record.enterpriseId" /></span>
        <span slot="status" slot-scope="text, record">
          {{ record.status === 1 ? '启用' : '禁用' }}
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-space>
            <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['business:working/device:edit']">编辑</a>
            <a @click="confirmHandleStatus(record)" v-hasPermi="['business:working/device:edit']" v-if="record.status !== 1">启用</a>
            <a @click="confirmHandleStatus(record)" v-hasPermi="['business:working/device:edit']" v-else>禁用</a>
            <a @click="handleDelete(record)" v-hasPermi="['business:working/device:remove']">删除</a>
          </a-space>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { delWorkingDevice, pageWorkingDevice, updateWorkingDevice } from '@/api/business/workingDevice'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import { enterpriseNameList } from '@/api/system/user'
import SelectTag from '@/components/SelectTag/index.vue'
import { selectListDevice } from '@/api/business/device'
import { mapGetters } from 'vuex'

export default {
  name: 'WorkingDevice',
  components: {
    SelectTag,
    CreateForm
  },
  mixins: [tableMixin],
  computed: {
    ...mapGetters(['isEnterpriseOrStaff', 'enterpriseId', 'deptId'])
  },
  data() {
    return {
      enterpriseList: [],
      deviceList: [],
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        enterpriseId: undefined,
        deviceName: undefined,
        code: null,
        name: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '运行设备状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          align: 'center'
        },
        {
          title: '所属企业',
          dataIndex: 'enterpriseId',
          scopedSlots: { customRender: 'enterpriseId' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '所属装置',
          dataIndex: 'deviceName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '运行设备名称',
          dataIndex: 'name',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '运行设备编号',
          dataIndex: 'code',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '创建人',
          dataIndex: 'creator',
          align: 'center'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {},
  created() {
    this.getList()
    enterpriseNameList().then(res => {
      this.enterpriseList = res.data
    })
    selectListDevice().then(res => {
      this.deviceList = res.data
    })
  },
  watch: {},
  methods: {
    /** 查询运行设备管理列表 */
    getList() {
      this.loading = true
      if (this.isEnterpriseOrStaff) {
        this.queryParam.enterpriseId = this.enterpriseId
      }
      pageWorkingDevice(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        enterpriseId: undefined,
        deviceName: undefined,
        code: undefined,
        name: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          let data = []
          that.ids.map(id => data.push({ 'id': id }))
          return delWorkingDevice(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
            })
        },
        onCancel() {
        }
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          that.download('business/working/device/export', {
            ...that.queryParam
          }, `运行设备管理_${new Date().getTime()}.xlsx`)
        },
        onCancel() {
        }
      })
    },
    confirmHandleStatus(row) {
      const oldStatus = row.status
      const text = row.status === 1 ? '启用' : '关闭'
      const status = row.status === 1 ? 0 : 1
      const data = {
        id: row.id,
        enterpriseId: row.enterpriseId,
        deviceName: row.deviceName,
        code: row.code,
        name: row.name,
        status: status
      }
      updateWorkingDevice(data).then((res) => {
        if (res.success) {
          row.status = row.status === 0 ? 1 : 0
          this.$message.success(text + '成功', 3)
        } else {
          row.status = oldStatus
          this.$message.error(`${text}失败, ${res.message}`, 3)
        }
      }).catch(() => {
        this.$message.error(text + '异常', 3)
      })
    }
  }
}
</script>
