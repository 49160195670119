<template>
  <div>
    <a-button type="link" @click="handleOpenModal">{{ imageCode }}</a-button>
    <a-modal v-model="visible" width="600px" :ok-text="null" centered>
      <div class="box">
        <a-avatar shape="square" :size="500" :src="src"/>
      </div>
      <template slot="footer">
        <a-button key="back" @click="handleCancel">取消</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>

export default {
  name: 'ImageCodeColumn',
  props: {
    imageCode: {
      type: String,
      default: undefined
    }
  },
  computed: {
    src: function () {
      return `${process.env.VUE_APP_OSS_CDN}${process.env.VUE_APP_OSS_IMAGE_PATH}/${this.imageCode}`
    }
  },
  components: {},
  data() {
    return {
      visible: false
    }
  },
  methods: {
    handleOpenModal: function () {
      this.visible = true
    },
    handleCancel: function () {
      this.visible = false
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
