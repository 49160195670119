<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24" v-if="!isEnterpriseOrStaff">
              <a-form-item label="企业名称" prop="enterpriseId">
                <a-select placeholder="请选择" v-model="queryParam.enterpriseId" style="width: 100%" allow-clear>
                  <a-select-option v-for="(d, index) in enterpriseList" :key="index" :value="d.key">{{ d.value }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="文件名称" prop="name">
                <a-input v-model="queryParam.name" placeholder="请输入文件名称" allow-clear />
              </a-form-item>
            </a-col>
            <a-col :md="!advanced && 6 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['business:calibration/document:add']">
          <a-icon type="plus" />
          新增
        </a-button>
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
        :enterpriseList="enterpriseList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="enterpriseId" slot-scope="text, record"><select-tag :options="enterpriseList" :value="record.enterpriseId" /></span>
        <span slot="name" slot-scope="text, record"><a :href="record.url" target="_blank">{{ record.name }}</a></span>
        <span slot="createTime" slot-scope="text, record">{{ parseTime(record.createTime) }}</span>
        <span slot="operation" slot-scope="text, record">
          <a-space>
            <a @click="handleDownload(record)" v-hasPermi="['business:calibration/document:edit']">下载</a>
            <a @click="handleDelete(record)" v-hasPermi="['business:calibration/document:remove']">删除</a>
          </a-space>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { delCalibrationDocument, pageCalibrationDocument } from '@/api/business/calibrationDocument'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import { enterpriseNameList } from '@/api/system/user'
import SelectTag from '@/components/SelectTag/index.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'CalibrationDocument',
  components: {
    SelectTag,
    CreateForm
  },
  mixins: [tableMixin],
  computed: {
    ...mapGetters(['isEnterpriseOrStaff', 'enterpriseId', 'deptId'])
  },
  data() {
    return {
      enterpriseList: [],
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        enterpriseId: undefined,
        name: null,
        url: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '企业名称',
          dataIndex: 'enterpriseId',
          scopedSlots: { customRender: 'enterpriseId' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '文件编码',
          dataIndex: 'id',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '文件名称',
          dataIndex: 'name',
          scopedSlots: { customRender: 'name' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '创建人',
          dataIndex: 'creator',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {},
  created() {
    this.getList()
    enterpriseNameList().then(res => {
      this.enterpriseList = res.data
    })
  },
  watch: {},
  methods: {
    /** 查询校准文件管理列表 */
    getList() {
      this.loading = true
      if (this.isEnterpriseOrStaff) {
        this.queryParam.enterpriseId = this.enterpriseId
      }
      pageCalibrationDocument(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        enterpriseId: undefined,
        name: undefined,
        url: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          const data = []
          that.ids.map(id => data.push({ 'id': id }))
          return delCalibrationDocument(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
            })
        },
        onCancel() {
        }
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          that.download('business/calibration/document/export', {
            ...that.queryParam
          }, `校准文件管理_${new Date().getTime()}.xlsx`)
        },
        onCancel() {
        }
      })
    },
    handleDownload(record) {
      this.downloadFile(record.url, record.name)
    },
    downloadFile(url, name) {
      const split = url.split('.')
      const suffix = split[split.length - 1]
      var xhr = new XMLHttpRequest()
      xhr.open('GET', url, true)
      xhr.responseType = 'blob' // 设置响应类型为blob，以便处理二进制数据
      xhr.onload = function() {
        if (xhr.status === 200) {
          var blob = xhr.response
          var link = document.createElement('a')
          link.style.display = 'none'
          link.href = window.URL.createObjectURL(blob)
          // 文件名自更改
          link.setAttribute('download', name + '.' + suffix)
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        }
      }
      xhr.send()
    }
  }
}
</script>
