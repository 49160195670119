import request from '@/utils/request'


// 查询气象五参数管理列表
export function listMeteorologyFive(query) {
  return request({
    url: '/business/meteorology-five/list',
    method: 'get',
    params: query
  })
}

// 查询气象五参数管理分页
export function pageMeteorologyFive(query) {
  return request({
    url: '/business/meteorology-five/page',
    method: 'get',
    params: query
  })
}

// 查询气象五参数管理详细
export function getMeteorologyFive(data) {
  return request({
    url: '/business/meteorology-five/detail',
    method: 'get',
    params: data
  })
}

// 新增气象五参数管理
export function addMeteorologyFive(data) {
  return request({
    url: '/business/meteorology-five/add',
    method: 'post',
    data: data
  })
}

// 修改气象五参数管理
export function updateMeteorologyFive(data) {
  return request({
    url: '/business/meteorology-five/edit',
    method: 'post',
    data: data
  })
}

// 删除气象五参数管理
export function delMeteorologyFive(data) {
  return request({
    url: '/business/meteorology-five/delete',
    method: 'post',
    data: data
  })
}
