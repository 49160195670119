<template>
  <a-drawer
    title="维修设置"
    width="35%"
    :visible="open"
    @close="onClose"
  >
    <a-form-model
      ref="form"
      :model="form"
      :rules="rules"
      :label-col="{ span: 8 }"
      :wrapper-col="{ span: 16 }"
    >
      <a-form-model-item label="首次维修限制天数" prop="firstTimeDayLimit">
        <a-input-number
          v-model="form.firstTimeDayLimit"
          placeholder="请输入"
          style="width: 100%;"
          :min="0"
          :max="100"
          :step="1"
        />
      </a-form-model-item>
      <a-form-model-item label="多次维修限制天数" prop="timesDayLimit">
        <a-input-number
          v-model="form.timesDayLimit"
          placeholder="请输入"
          style="width: 100%;"
          :min="0"
          :max="100"
          :step="1"
        />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getConfigKey, updateConfigKey } from '@/api/system/config'

export default {
  name: 'RepairSettingForm',
  props: {},
  components: {},
  data() {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        firstTimeDayLimit: 7,
        timesDayLimit: 7
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        firstTimeDayLimit: [
          { required: true, message: '首次维修限制天数不能为空', trigger: 'blur' }
        ],
        timesDayLimit: [
          { required: true, message: '多次维修限制天数不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {},
  created() {
  },
  computed: {},
  watch: {},
  mounted() {
  },
  methods: {
    getData() {
      getConfigKey('repair.detail.firstTimeDayLimit').then(res => {
        this.form.firstTimeDayLimit = res.data
      })
      getConfigKey('repair.detail.timesDayLimit').then(res => {
        this.form.timesDayLimit = res.data
      })
    },
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.form = {
        firstTimeDayLimit: 7,
        timesDayLimit: 7
      }
    },
    /** 新增按钮操作 */
    handleOpen() {
      this.reset()
      this.open = true
      this.getData()
    },
    /** 提交按钮 */
    submitForm: async function() {
      this.$refs.form.validate(async valid => {
        if (!valid) {
          return false
        }
        this.submitLoading = true
        await updateConfigKey('repair.detail.firstTimeDayLimit', this.form.firstTimeDayLimit)
        await updateConfigKey('repair.detail.timesDayLimit', this.form.timesDayLimit)
        this.$message.success('更新成功', 3)
        this.open = false
        this.submitLoading = false
        this.$emit('ok')
      })
    }
  }
}
</script>
