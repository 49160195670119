import request from '@/utils/request'


// 查询校准文件管理列表
export function listCalibrationDocument(query) {
  return request({
    url: '/business/calibration-document/list',
    method: 'get',
    params: query
  })
}

// 查询校准文件管理分页
export function pageCalibrationDocument(query) {
  return request({
    url: '/business/calibration-document/page',
    method: 'get',
    params: query
  })
}

// 查询校准文件管理详细
export function getCalibrationDocument(data) {
  return request({
    url: '/business/calibration-document/detail',
    method: 'get',
    params: data
  })
}

// 新增校准文件管理
export function addCalibrationDocument(data) {
  return request({
    url: '/business/calibration-document/add',
    method: 'post',
    data: data
  })
}

// 修改校准文件管理
export function updateCalibrationDocument(data) {
  return request({
    url: '/business/calibration-document/edit',
    method: 'post',
    data: data
  })
}

// 删除校准文件管理
export function delCalibrationDocument(data) {
  return request({
    url: '/business/calibration-document/delete',
    method: 'post',
    data: data
  })
}
