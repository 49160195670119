<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-radio-group v-model="form.type" @change="handleTypeChange">
      <a-radio-button :value="1">普通检测</a-radio-button>
      <a-radio-button :value="2">追踪检测</a-radio-button>
    </a-radio-group>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="企业名称" prop="enterpriseId" v-if="!isEnterpriseOrStaff">
        <a-select placeholder="请选择" v-model="form.enterpriseId" style="width: 100%" allow-clear>
          <a-select-option v-for="(d, index) in enterpriseList" :key="index" :value="d.key">{{ d.value }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="季度" prop="quarter">
        <a-select placeholder="请选择" v-model="form.quarter" style="width: 100%" allow-clear>
          <a-select-option :value="1">1季度</a-select-option>
          <a-select-option :value="2">2季度</a-select-option>
          <a-select-option :value="3">3季度</a-select-option>
          <a-select-option :value="4">4季度</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="密封点分类" prop="sealingPointId">
        <a-select placeholder="请选择" v-model="form.sealingPointId" style="width: 100%" allow-clear>
          <a-select-option v-for="(d, index) in sealingPointDictList" :key="index" :value="d.key">{{ d.value }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="检测方式" prop="way" v-if="form.type === 1">
        <a-select placeholder="请选择" v-model="form.way" style="width: 100%" allow-clear>
          <a-select-option :value="1">常规检测</a-select-option>
          <a-select-option :value="2">非常规检测</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="检测方式" prop="type" v-if="form.type === 2">
        <a-select disabled v-model="form.type">
          <a-select-option :value="2">追踪检测</a-select-option>
        </a-select>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getRepairTask, addRepairTask, updateRepairTask } from '@/api/business/repairTask'
import { mapGetters } from 'vuex'

export default {
  name: 'CreateForm',
  props: {
    enterpriseList: {
      type: Array
    },
    sealingPointDictList: {
      type: Array
    }
  },
  components: {},
  computed: {
    ...mapGetters(['isEnterpriseOrStaff', 'enterpriseId', 'deptId'])
  },
  data() {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        enterpriseId: undefined,
        quarter: undefined,
        sealingPointId: undefined,
        type: 1,
        way: undefined,
        status: 0,
        total: 0,
        inspectionCount: 0,
        leakageCount: 0,
        createTime: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        enterpriseId: [
          { required: true, message: '企业名称不能为空', trigger: 'blur' }
        ],
        quarter: [
          { required: true, message: '季度不能为空', trigger: 'blur' }
        ],
        sealingPointId: [
          { required: true, message: '密封点分类不能为空', trigger: 'blur' }
        ],
        way: [
          { required: true, message: '检测方式不能为空', trigger: 'change' }
        ]
      }
    }
  },
  filters: {},
  created() {
  },
  watch: {},
  mounted() {
  },
  methods: {
    onClose() {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        enterpriseId: undefined,
        quarter: undefined,
        sealingPointId: undefined,
        type: 1,
        way: undefined,
        status: 0,
        total: 0,
        inspectionCount: 0,
        leakageCount: 0,
        createTime: null
      }
      this.$nextTick(() => {
        this.$refs.form && this.$refs.form.resetFields()
      })
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getRepairTask({ 'id': id }).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs.form.validate(valid => {
        if (!valid) {
          return false
        }
        if (this.isEnterpriseOrStaff) {
          this.form.enterpriseId = this.enterpriseId
        }
        this.submitLoading = true
        if (this.form.id !== undefined && this.form.id !== null) {
          updateRepairTask(this.form).then(response => {
            this.$message.success(
              '修改成功',
              3
            )
            this.open = false
            this.$emit('ok')
          }).finally(() => {
            this.submitLoading = false
          })
        } else {
          addRepairTask(this.form).then(response => {
            this.$message.success(
              '新增成功',
              3
            )
            this.open = false
            this.$emit('ok')
          }).finally(() => {
            this.submitLoading = false
          })
        }
      })
    },
    handleTypeChange() {
      console.log('type : ', this.form.type)
      if (this.form.type === 2) {
        this.form.way = 1
      } else if (this.form.type === 1) {
        this.form.way = undefined
      }
    }
  }
}
</script>
