<template>
  <a-drawer title="修复任务" width="35%" :visible="open" @close="onClose">
    <a-form-model
      ref="form"
      :model="form"
      :rules="rules"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 16 }"
    >
      <a-form-model-item label="维修人" prop="repairer">
        <a-input v-model="form.repairer" placeholder="请输入" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="维修时间" prop="repairTime">
        <a-date-picker
          v-model="form.repairTime"
          show-time
          type="date"
          placeholder="请输入"
          style="width: 100%;"
          value-format="YYYY-MM-DD HH:mm:ss"
          format="YYYY-MM-DD HH:mm:ss"
        />
      </a-form-model-item>
      <a-form-model-item label="维修措施" prop="repairMeasure">
        <a-textarea v-model="form.repairMeasure" placeholder="请输入" allow-clear />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getRepairDetail, updateRepairDetail } from '@/api/business/repairDetail'

export default {
  name: 'RepairForm',
  props: {},
  components: {},
  data() {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        repairer: null,
        repairTime: null,
        repairMeasure: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        repairer: [
          { required: true, message: '维修人不能为空', trigger: 'blur' }
        ],
        repairTime: [
          { required: true, message: '维修时间不能为空', trigger: 'change' }
        ],
        repairMeasure: [
          { required: true, message: '维修措施不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {},
  created() {
  },
  computed: {},
  watch: {},
  mounted() {
  },
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        repairer: null,
        repairTime: null,
        repairMeasure: null
      }
      this.$nextTick(() => {
        this.$refs.form && this.$refs.form.resetFields()
      })
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset()
      const id = row.id
      getRepairDetail({ 'id': id }).then(response => {
        this.form = response.data
        this.open = true
      })
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs.form.validate(valid => {
        if (!valid) {
          return false
        }
        this.submitLoading = true
        this.form.status = 3
        this.form.count = this.form.count ? 1 : (this.form.count + 1)
        updateRepairDetail(this.form).then(response => {
          this.$message.success(
            '修改成功',
            3
          )
          this.open = false
          this.$emit('ok')
        }).finally(() => {
          this.submitLoading = false
        })
      })
    }
  }
}
</script>
