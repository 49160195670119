<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="企业名称" prop="enterpriseId" v-if="!isEnterpriseOrStaff">
        <a-select placeholder="请选择" v-model="form.enterpriseId" style="width: 100%" allow-clear>
          <a-select-option v-for="(d, index) in enterpriseList" :key="index" :value="d.key">{{ d.value }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="装置名称" prop="name">
        <a-input v-model="form.name" placeholder="请输入装置名称" />
      </a-form-model-item>
      <a-form-model-item label="装置编码" prop="code">
        <a-input v-model="form.code" placeholder="请输入装置编码" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getDevice, addDevice, updateDevice } from '@/api/business/device'
import { mapGetters } from 'vuex'

export default {
  name: 'CreateForm',
  props: {
    enterpriseList: {
      type: Array,
      required: true
    }
  },
  components: {},
  computed: {
    ...mapGetters(['isEnterpriseOrStaff', 'enterpriseId', 'deptId'])
  },
  data() {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        enterpriseId: undefined,
        name: null,
        code: null,
        createTime: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        enterpriseId: [
          { required: true, message: '企业名称不能为空', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '装置名称不能为空', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '装置编码不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {},
  created() {
  },
  watch: {},
  mounted() {
  },
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        enterpriseId: undefined,
        name: null,
        code: null,
        createTime: null
      }
      this.$nextTick(() => {
        this.$refs.form && this.$refs.form.resetFields()
      })
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getDevice({ 'id': id }).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.isEnterpriseOrStaff) {
            this.form.enterpriseId = this.enterpriseId
          }
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateDevice(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addDevice(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
