import request from '@/utils/request'


// 查询运行设备管理列表
export function listWorkingDevice(query) {
  return request({
    url: '/business/working-device/list',
    method: 'get',
    params: query
  })
}

// 查询运行设备管理分页
export function pageWorkingDevice(query) {
  return request({
    url: '/business/working-device/page',
    method: 'get',
    params: query
  })
}

// 查询运行设备管理详细
export function getWorkingDevice(data) {
  return request({
    url: '/business/working-device/detail',
    method: 'get',
    params: data
  })
}

// 新增运行设备管理
export function addWorkingDevice(data) {
  return request({
    url: '/business/working-device/add',
    method: 'post',
    data: data
  })
}

// 修改运行设备管理
export function updateWorkingDevice(data) {
  return request({
    url: '/business/working-device/edit',
    method: 'post',
    data: data
  })
}

// 删除运行设备管理
export function delWorkingDevice(data) {
  return request({
    url: '/business/working-device/delete',
    method: 'post',
    data: data
  })
}
