import request from '@/utils/request'

// 查询密封点分类字典列表
export function listSealingPointDict(query) {
  return request({
    url: '/business/sealing-point-dict/list',
    method: 'get',
    params: query
  })
}

// 查询密封点分类字典分页
export function pageSealingPointDict(query) {
  return request({
    url: '/business/sealing-point-dict/page',
    method: 'get',
    params: query
  })
}

// 查询密封点分类字典详细
export function getSealingPointDict(data) {
  return request({
    url: '/business/sealing-point-dict/detail',
    method: 'get',
    params: data
  })
}

// 新增密封点分类字典
export function addSealingPointDict(data) {
  return request({
    url: '/business/sealing-point-dict/add',
    method: 'post',
    data: data
  })
}

// 修改密封点分类字典
export function updateSealingPointDict(data) {
  return request({
    url: '/business/sealing-point-dict/edit',
    method: 'post',
    data: data
  })
}

// 删除密封点分类字典
export function delSealingPointDict(data) {
  return request({
    url: '/business/sealing-point-dict/delete',
    method: 'post',
    data: data
  })
}

export function selectListSealingPointDict() {
  return request({
    url: '/business/sealing-point-dict/selectList',
    method: 'get'
  })
}