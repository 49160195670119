<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form"  :rules="rules">
      <a-form-model-item label="检测仪名称" prop="name">
        <a-input v-model="form.name" placeholder="请输入检测仪名称" />
      </a-form-model-item>
      <a-form-model-item label="检测仪编码" prop="code">
        <a-input v-model="form.code" placeholder="请输入检测仪编码" />
      </a-form-model-item>
      <a-form-model-item label="检测仪型号" prop="mode">
        <a-input v-model="form.mode" placeholder="请输入检测仪型号" />
      </a-form-model-item>
      <a-form-model-item label="创建人" prop="creator" v-if="form.id">
        <a-input v-model="form.creator" placeholder="" :disabled="true" />
      </a-form-model-item>
      <a-form-model-item label="创建时间" prop="createTime" v-if="form.id">
        <a-input v-model="form.createTime" placeholder="" :disabled="true" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getDetector, addDetector, updateDetector } from '@/api/business/detector'

export default {
  name: 'CreateForm',
  props: {},
  components: {},
  data() {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        name: null,
        code: null,
        mode: null,
        createTime: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        name: [
          { required: true, message: '检测仪器名称不能为空', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '检测仪器编号不能为空', trigger: 'blur' }
        ],
        mode: [
          { required: true, message: '检测仪器型号不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {},
  created() {
  },
  computed: {},
  watch: {},
  mounted() {
  },
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        name: null,
        code: null,
        mode: null,
        createTime: null
      }
      this.$nextTick(() => {
        this.$refs.form && this.$refs.form.resetFields()
      })
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getDetector({ 'id': id }).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateDetector(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addDetector(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
