import request from '@/utils/request'


// 查询装置管理列表
export function listDevice(query) {
  return request({
    url: '/business/device/list',
    method: 'get',
    params: query
  })
}

// 查询装置管理分页
export function pageDevice(query) {
  return request({
    url: '/business/device/page',
    method: 'get',
    params: query
  })
}

// 查询装置管理详细
export function getDevice(data) {
  return request({
    url: '/business/device/detail',
    method: 'get',
    params: data
  })
}

// 新增装置管理
export function addDevice(data) {
  return request({
    url: '/business/device/add',
    method: 'post',
    data: data
  })
}

// 修改装置管理
export function updateDevice(data) {
  return request({
    url: '/business/device/edit',
    method: 'post',
    data: data
  })
}

// 删除装置管理
export function delDevice(data) {
  return request({
    url: '/business/device/delete',
    method: 'post',
    data: data
  })
}

export function selectListDevice() {
  return request({
    url: '/business/device/selectList',
    method: 'get'
  })
}