<template>
  <div>
    <template v-for="(item) in options">
      <template v-if="values.includes(item.key)">
        <span :key="item.key">
          {{ item.value }}
        </span>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: 'SelectTag',
  props: {
    options: {
      type: Array,
      default: null
    },
    value: {
      type: [Number, String, Array],
      default: null
    }
  },
  computed: {
    values() {
      if (this.value !== null && typeof this.value !== 'undefined') {
        return Array.isArray(this.value) ? this.value : [String(this.value)]
      } else {
        return []
      }
    }
  },
  filters: {}
}
</script>
