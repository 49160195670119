<template>
  <a-card :bordered="false" :body-style="{padding:0}">
    <!-- 操作 -->
    <div class="table-operations">
      <a-button type="primary" @click="$refs.createForm2.handleAdd()" v-hasPermi="['business:sub/component:add']">
        <a-icon type="plus" />
        新增
      </a-button>
    </div>
    <!-- 增加修改 -->
    <create-form2
      ref="createForm2"
      :component-id="componentId"
      @ok="getList"
    />
    <!-- 数据展示 -->
    <a-table
      :loading="loading"
      :size="tableSize"
      rowKey="id"
      :columns="columns"
      :data-source="list"
      :pagination="false"
      :bordered="tableBordered"
    >
      <span slot="createTime" slot-scope="text, record">
        {{ parseTime(record.createTime) }}
      </span>
      <span slot="operation" slot-scope="text, record">
        <a-space>
          <a @click="$refs.createForm2.handleUpdate(record, undefined)" v-hasPermi="['business:sub/component:edit']">编辑</a>
          <a @click="handleDelete(record)" v-hasPermi="['business:sub/component:remove']">删除</a>
        </a-space>
      </span>
    </a-table>
    <!-- 分页 -->
    <a-pagination
      class="ant-table-pagination"
      show-size-changer
      show-quick-jumper
      :current="queryParam.pageNum"
      :total="total"
      :page-size="queryParam.pageSize"
      :showTotal="total => `共 ${total} 条`"
      @showSizeChange="onShowSizeChange"
      @change="changeSize"
    />
  </a-card>
</template>

<script>
import { delSubComponent, pageSubComponent } from '@/api/business/subComponent'
import { tableMixin } from '@/store/table-mixin'
import CreateForm2 from '@/views/business/subComponent/modules/CreateForm.vue'

export default {
  name: 'SubComponent',
  components: {
    CreateForm2
  },
  props: {
    componentId: {
      type: String,
      required: true
    }
  },
  mixins: [tableMixin],
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        name: null,
        letter: null,
        componentId: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '子组件类型名称',
          dataIndex: 'name',
          align: 'center'
        },
        {
          title: '对应字母',
          dataIndex: 'letter',
          align: 'center'
        },
        {
          title: '创建人',
          dataIndex: 'creator',
          align: 'center'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {},
  created() {
    this.queryParam.componentId = this.componentId
    this.getList()
  },
  computed: {},
  watch: {
    componentId(val, old) {
      this.queryParam.componentId = this.componentId
      this.getList()
    }
  },
  methods: {
    /** 查询子组件类型列表 */
    getList() {
      this.loading = true
      pageSubComponent(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        name: undefined,
        letter: undefined,
        componentId: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          const data = []
          that.ids.map(id => data.push({ 'id': id }))
          return delSubComponent(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
            })
        },
        onCancel() {
        }
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          that.download('business/sub/component/export', {
            ...that.queryParam
          }, `子组件类型_${new Date().getTime()}.xlsx`)
        },
        onCancel() {
        }
      })
    }
  }
}
</script>
