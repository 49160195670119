<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="组件类型名称" prop="name">
                <a-input v-model="queryParam.name" placeholder="请输入组件类型名称" allow-clear />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="密封点分类" prop="sealingPointId">
                <a-select placeholder="请选择" v-model="queryParam.sealingPointId" style="width: 100%" allow-clear>
                  <a-select-option v-for="(d, index) in sealingPointDictList" :key="index" :value="d.key">{{ d.value }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['business:component/dict:add']">
          <a-icon type="plus" />
          新增
        </a-button>
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
        :sealingPointDictList="sealingPointDictList"
      />
      <sub-component-drawer
        ref="subComponentDrawerRef"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="sealingPointId" slot-scope="text, record">
          <select-tag :options="sealingPointDictList" :value="record.sealingPointId" />
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-space>
            <a @click="$refs.subComponentDrawerRef.handleOpen(record)">组件子类型</a>
            <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['business:component/dict:edit']">编辑</a>
            <a @click="handleDelete(record)" v-hasPermi="['business:component/dict:remove']">删除</a>
          </a-space>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { delComponentDict, pageComponentDict } from '@/api/business/componentDict'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import { selectListSealingPointDict } from '@/api/business/sealingPointDict'
import SelectTag from '@/components/SelectTag/index.vue'
import SubComponentDrawer from '@/views/business/componentDict/modules/SubComponentDrawer.vue'

export default {
  name: 'ComponentDict',
  components: {
    SubComponentDrawer,
    SelectTag,
    CreateForm
  },
  mixins: [tableMixin],
  data() {
    return {
      sealingPointDictList: [],
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        name: undefined,
        sealingPointId: undefined,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '组件类型名称',
          dataIndex: 'name',
          align: 'center'
        },
        {
          title: '密封点分类',
          dataIndex: 'sealingPointId',
          scopedSlots: { customRender: 'sealingPointId' },
          align: 'center'
        },
        {
          title: '创建人',
          dataIndex: 'creator',
          align: 'center'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {},
  created() {
    this.getList()
    selectListSealingPointDict().then(res => {
      console.log('res : ', res)
      this.sealingPointDictList = res.data
    })
  },
  computed: {},
  watch: {},
  methods: {
    /** 查询组件分类字典列表 */
    getList() {
      this.loading = true
      pageComponentDict(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        name: undefined,
        sealingPointId: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          let data = []
          that.ids.map(id => data.push({ 'id': id }))
          return delComponentDict(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
            })
        },
        onCancel() {
        }
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          that.download('business/component/dict/export', {
            ...that.queryParam
          }, `组件分类字典_${new Date().getTime()}.xlsx`)
        },
        onCancel() {
        }
      })
    }
  }
}
</script>
