<template>
  <!-- 增加修改 -->
  <a-drawer :title="formTitle" width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose" :destroyOnClose="true">
    <a-form-model ref="form" :model="form"  :rules="rules">
      <a-form-model-item label="员工姓名" prop="nickName">
        <a-input v-model="form.nickName" placeholder="请输入" :maxLength="30" />
      </a-form-model-item>
      <a-form-model-item label="员工手机号" prop="phonenumber">
        <a-input v-model="form.phonenumber" placeholder="请输入" />
      </a-form-model-item>
      <a-form-model-item label="员工性别" prop="sex">
        <a-radio-group v-model="form.sex" button-style="solid">
          <a-radio-button v-for="(d, index) in dict.type['sys_user_sex']" :key="index" :value="d.value">{{ d.label }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="最后一次登录时间" prop="loginDate" v-if="form.userId">
        <a-input v-model="form.loginDate" placeholder="" :disabled="true" />
      </a-form-model-item>
      <a-form-model-item label="创建人" prop="creator" v-if="form.userId">
        <a-input v-model="form.creator" placeholder="请输入" :disabled="true" />
      </a-form-model-item>
      <a-form-model-item label="创建时间" prop="createTime" v-if="form.userId">
        <a-input v-model="form.createTime" placeholder="请输入" :disabled="true" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>

import { getUser, addUser, updateUser } from '@/api/system/user'
import constants from '@/utils/constants'
import { mapGetters } from 'vuex'

export default {
  name: 'CreateForm',
  props: {},
  components: {},
  dicts: ['sys_user_sex'],
  computed: {
    ...mapGetters(['isEnterpriseOrStaff', 'enterpriseId', 'deptId'])
  },
  data() {
    return {
      submitLoading: false,
      replaceFields: { children: 'children', title: 'label', key: 'id', value: 'id' },
      // 默认密码
      initPassword: undefined,
      formTitle: '',
      // 表单参数
      form: {
        userId: undefined,
        deptId: constants.staffDeptId,
        userName: undefined,
        nickName: undefined,
        password: undefined,
        phonenumber: undefined,
        email: undefined,
        sex: '3',
        status: '0',
        remark: undefined,
        postIds: [],
        roleIds: [constants.staffRoleId]
      },
      open: false,
      rules: {
        nickName: [
          { required: true, message: '员工姓名不能为空', trigger: 'blur' }
        ],
        phonenumber: [
          { required: true, message: '员工手机号不能为空', trigger: 'blur' },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: '请正确填写手机号',
            trigger: 'blur'
          }
        ],
        sex: [
          { required: true, message: '员工性别不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {},
  created() {
    this.getConfigKey('sys.user.initPassword').then(response => {
      this.initPassword = response.data
      this.form.password = response.data
    })
  },
  watch: {},
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.form = {
        userId: undefined,
        deptId: constants.staffDeptId,
        userName: undefined,
        nickName: undefined,
        password: undefined,
        phonenumber: undefined,
        email: undefined,
        sex: '3',
        status: '0',
        remark: undefined,
        postIds: [],
        roleIds: [constants.staffRoleId]
      }
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset()
      this.open = true
      this.formTitle = '新增员工'
      this.form.password = this.initPassword
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      const userId = row ? row.userId : ids
      getUser(userId).then(response => {
        this.form = response.data.data
        this.open = true
        this.formTitle = '编辑员工'
      })
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.isEnterpriseOrStaff) {
            this.form.enterpriseId = this.enterpriseId
          }
          this.form.userName = this.form.phonenumber
          this.submitLoading = true
          if (this.form.userId !== undefined) {
            updateUser(this.form).then(response => {
              this.$message.success(
                '编辑成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addUser(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
