<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="5" :sm="24">
              <a-form-item label="检测ID" prop="ledgerId">
                <a-input v-model="queryParam.id" placeholder="请输入" allow-clear />
              </a-form-item>
            </a-col>
            <a-col :md="5" :sm="24" v-if="!isEnterpriseOrStaff">
              <a-form-item label="企业名称" prop="enterpriseId">
                <a-select placeholder="请选择" v-model="queryParam.enterpriseId" style="width: 100%" allow-clear>
                  <a-select-option v-for="(d, index) in enterpriseList" :key="index" :value="d.key">{{ d.value }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="5" :sm="24">
              <a-form-item label="季度" prop="quarter">
                <a-select placeholder="请选择" v-model="queryParam.quarter" style="width: 100%" allow-clear>
                  <a-select-option :value="1">1季度</a-select-option>
                  <a-select-option :value="2">2季度</a-select-option>
                  <a-select-option :value="3">3季度</a-select-option>
                  <a-select-option :value="4">4季度</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="5" :sm="24">
              <a-form-item label="密封点分类" prop="sealingPointId">
                <a-select placeholder="请选择" v-model="queryParam.sealingPointId" style="width: 100%" allow-clear>
                  <a-select-option v-for="(d, index) in sealingPointDictList" :key="index" :value="d.key">{{ d.value }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="!advanced && 4 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery">查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery">重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <repair-form ref="repairForm" @ok="getList" />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
        :scroll="{ x: true }"
      >
        <div slot="imageCode" slot-scope="text, record">
          <image-code-column :image-code="record.ledger.imageCode"/>
        </div>
        <span slot="status">延时维修</span>
        <span slot="delayHandleTime" slot-scope="text, record">{{ parseTime(record.delayHandleTime, '{y}/{m}/{d}') }}</span>
        <span slot="inspectionTime" slot-scope="text, record">{{ parseTime(new Date(record.inspectionTime), '{y}/{m}/{d} {h}:{i}') }}</span>
        <span slot="enterpriseId" slot-scope="text, record">
          <select-tag :options="enterpriseList" :value="record.enterpriseId" />
        </span>
        <span slot="sealingPointId" slot-scope="text, record">
          <select-tag :options="sealingPointDictList" :value="record.sealingPointId" />
        </span>
        <span slot="quarter" slot-scope="text, record">
          <span>{{ record.quarter }}季度</span>
        </span>
        <span slot="isInspection" slot-scope="text, record">{{ record.isInspection ? '是' : '否' }}</span>
        <span slot="isLeakage" slot-scope="text, record">{{ record.isLeakage ? '是' : '否' }}</span>
        <span slot="operation" slot-scope="text, record">
          <a-space>
            <a @click="$refs.repairForm.handleUpdate(record)">修复</a>
          </a-space>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import {pageRepairDetail} from '@/api/business/repairDetail'
import CreateForm from './modules/CreateForm'
import {tableMixin} from '@/store/table-mixin'
import {enterpriseNameList} from '@/api/system/user'
import {selectListSealingPointDict} from '@/api/business/sealingPointDict'
import SelectTag from '@/components/SelectTag/index.vue'
import RepairForm from '@/views/business/repairDetail/modules/RepairForm.vue'
import {mapGetters} from 'vuex'
import ImageCodeColumn from "@/components/ImageCodeColumn/index.vue";

export default {
  name: 'RepairDetail',
  components: {
    ImageCodeColumn,
    RepairForm,
    SelectTag,
    CreateForm
  },
  mixins: [tableMixin],
  computed: {
    ...mapGetters(['isEnterpriseOrStaff', 'enterpriseId', 'deptId'])
  },
  data() {
    return {
      enterpriseList: [],
      sealingPointDictList: [],
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        id: undefined,
        taskId: undefined,
        enterpriseId: undefined,
        ledgerId: undefined,
        quarter: undefined,
        sealingPointId: undefined,
        repairer: null,
        repairTime: null,
        repairMeasure: null,
        delayHandleUser: null,
        delayHandleTime: null,
        delayReason: null,
        delayExpectedRepairTime: null,
        isInspection: undefined,
        isLeakage: undefined,
        inspector: null,
        inspectionTime: null,
        inspectionDevice: null,
        inspectionValue: null,
        inspectionLeakagePlace: null,
        inspectionBackgroundValue: null,
        inspectionRoom: null,
        inspectionPath: null,
        inspectionDripNumber: null,
        inspectionMark: null,
        reInspector: null,
        reInspectionTime: null,
        reInspectionDevice: null,
        reInspectionValue: null,
        reInspectionBackgroundValue: null,
        reInspectionRoom: null,
        reInspectionPath: null,
        reInspectionDripNumber: null,
        reInspectionMark: null,
        status: null,
        count: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '检测ID',
          dataIndex: 'id',
          ellipsis: true,
          align: 'center',
          fixed: 'left',
          width: 100
        },
        {
          title: '维修状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          ellipsis: true,
          align: 'center',
          width: 80
        },
        {
          title: '办理人',
          dataIndex: 'delayHandleUser',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '办理日期',
          dataIndex: 'delayHandleTime',
          scopedSlots: { customRender: 'delayHandleTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '延时修复原因',
          dataIndex: 'delayReason',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '企业名称',
          dataIndex: 'enterpriseId',
          scopedSlots: { customRender: 'enterpriseId' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '季度',
          dataIndex: 'quarter',
          scopedSlots: { customRender: 'quarter' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '密封点分类',
          dataIndex: 'sealingPointId',
          scopedSlots: { customRender: 'sealingPointId' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '装置名称',
          dataIndex: 'ledger.deviceName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '装置名称',
          dataIndex: 'ledger.deviceName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '装置编码-区域',
          dataIndex: 'ledger.deviceCode',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '运行设备',
          dataIndex: 'ledger.runningEquipment',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '标签',
          dataIndex: 'ledger.label',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '位置描述',
          dataIndex: 'ledger.describe',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '组件类型',
          dataIndex: 'ledger.componentType',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '组件子类型',
          dataIndex: 'ledger.componentSubtype',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '泄漏阈值',
          dataIndex: 'ledger.leakageThreshold',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '检测最小停留时间（秒）',
          dataIndex: 'ledger.detectMinimumDwellTime',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '图片编码',
          dataIndex: 'ledger.imageCode',
          ellipsis: true,
          align: 'center',
          scopedSlots: { customRender: 'imageCode' }
        },
        {
          title: '检测人员',
          dataIndex: 'inspector',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '检测日期',
          dataIndex: 'inspectionTime',
          scopedSlots: { customRender: 'inspectionTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '检测仪器',
          dataIndex: 'inspectionDevice',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '检测值',
          dataIndex: 'inspectionValue',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '是否泄漏',
          dataIndex: 'isLeakage',
          scopedSlots: { customRender: 'isLeakage' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '泄漏部位',
          dataIndex: 'inspectionLeakagePlace',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '背景值',
          dataIndex: 'inspectionBackgroundValue',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '室内',
          dataIndex: 'inspectionRoom',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '检测路径',
          dataIndex: 'inspectionPath',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '每分滴漏数',
          dataIndex: 'inspectionDripNumber',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '备注',
          dataIndex: 'inspectionMark',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
          align: 'center',
          width: 80,
          fixed: 'right'
        }
      ]
    }
  },
  filters: {},
  created() {
    this.getList()
    enterpriseNameList().then(res => {
      this.enterpriseList = res.data
    })
    selectListSealingPointDict().then(res => {
      this.sealingPointDictList = res.data
    })
  },
  watch: {},
  methods: {
    showLessDay(dateStr, offset) {
      const startDate = new Date(dateStr)
      startDate.setHours(0, 0, 0, 0)
      const oneDay = 24 * 60 * 60 * 1000 // 一天的毫秒数
      const endDate = new Date(startDate.getTime() + (offset * oneDay))
      return Math.round((endDate.getTime() - startDate.getTime()) / oneDay)
    },
    /** 查询维修明细管理列表 */
    getList() {
      this.queryParam.status = 2
      this.loading = true
      if (this.isEnterpriseOrStaff) {
        this.queryParam.enterpriseId = this.enterpriseId
      }
      pageRepairDetail(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        id: undefined,
        enterpriseId: undefined,
        ledgerId: undefined,
        quarter: undefined,
        sealingPointId: undefined,
        repairer: undefined,
        repairTime: undefined,
        repairMeasure: undefined,
        delayHandleUser: undefined,
        delayHandleTime: undefined,
        delayReason: undefined,
        delayExpectedRepairTime: undefined,
        isInspection: undefined,
        isLeakage: undefined,
        inspector: undefined,
        inspectionTime: undefined,
        inspectionDevice: undefined,
        inspectionValue: undefined,
        inspectionLeakagePlace: undefined,
        inspectionBackgroundValue: undefined,
        inspectionRoom: undefined,
        inspectionPath: undefined,
        inspectionDripNumber: undefined,
        inspectionMark: undefined,
        reInspector: undefined,
        reInspectionTime: undefined,
        reInspectionDevice: undefined,
        reInspectionValue: undefined,
        reInspectionBackgroundValue: undefined,
        reInspectionRoom: undefined,
        reInspectionPath: undefined,
        reInspectionDripNumber: undefined,
        reInspectionMark: undefined,
        status: undefined,
        count: undefined,
        deleted: false,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    }
  }
}
</script>
