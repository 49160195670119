<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="企业名称" prop="enterpriseId" v-if="!isEnterpriseOrStaff">
        <a-input v-model="form.enterpriseId" placeholder="请输入企业ID" />
      </a-form-model-item>
      <a-form-model-item label="台账ID" prop="ledgerId">
        <a-input v-model="form.ledgerId" placeholder="请输入台账ID" />
      </a-form-model-item>
      <a-form-model-item label="季度，1：1季度，2：2季度，3：3季度，4：4季度" prop="quarter">
        <a-input v-model="form.quarter" placeholder="请输入季度，1：1季度，2：2季度，3：3季度，4：4季度" />
      </a-form-model-item>
      <a-form-model-item label="密封点分类ID" prop="sealingPointId">
        <a-input v-model="form.sealingPointId" placeholder="请输入密封点分类ID" />
      </a-form-model-item>
      <a-form-model-item label="维修人" prop="repairer">
        <a-input v-model="form.repairer" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="维修时间" prop="repairTime">
        <a-input v-model="form.repairTime" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="维修措施" prop="repairMeasure">
        <a-input v-model="form.repairMeasure" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="延时修复办理人" prop="delayHandleUser">
        <a-input v-model="form.delayHandleUser" placeholder="请输入延时修复办理人" />
      </a-form-model-item>
      <a-form-model-item label="延时修复办理日期" prop="delayHandleTime">
        <a-input v-model="form.delayHandleTime" placeholder="请输入延时修复办理日期" />
      </a-form-model-item>
      <a-form-model-item label="延时修复原因" prop="delayReason">
        <a-input v-model="form.delayReason" placeholder="请输入延时修复原因" />
      </a-form-model-item>
      <a-form-model-item label="延时修复预计维修日期" prop="delayExpectedRepairTime">
        <a-input v-model="form.delayExpectedRepairTime" placeholder="请输入延时修复预计维修日期" />
      </a-form-model-item>
      <a-form-model-item label="是否检测" prop="isInspection">
        <a-input v-model="form.isInspection" placeholder="请输入是否检测" />
      </a-form-model-item>
      <a-form-model-item label="是否泄漏" prop="isLeakage">
        <a-input v-model="form.isLeakage" placeholder="请输入是否泄漏" />
      </a-form-model-item>
      <a-form-model-item label="检测人员" prop="inspector">
        <a-input v-model="form.inspector" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="检测日期" prop="inspectionTime">
        <a-input v-model="form.inspectionTime" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="检测仪器" prop="inspectionDevice">
        <a-input v-model="form.inspectionDevice" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="检测值" prop="inspectionValue">
        <a-input v-model="form.inspectionValue" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="泄漏部位" prop="inspectionLeakagePlace">
        <a-input v-model="form.inspectionLeakagePlace" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="背景值" prop="inspectionBackgroundValue">
        <a-input v-model="form.inspectionBackgroundValue" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="室内" prop="inspectionRoom">
        <a-input v-model="form.inspectionRoom" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="检测路径" prop="inspectionPath">
        <a-input v-model="form.inspectionPath" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="每分滴漏数" prop="inspectionDripNumber">
        <a-input v-model="form.inspectionDripNumber" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="检测备注" prop="inspectionMark">
        <a-input v-model="form.inspectionMark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="复检人员" prop="reInspector">
        <a-input v-model="form.reInspector" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="复检日期" prop="reInspectionTime">
        <a-input v-model="form.reInspectionTime" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="复检仪器" prop="reInspectionDevice">
        <a-input v-model="form.reInspectionDevice" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="复检值" prop="reInspectionValue">
        <a-input v-model="form.reInspectionValue" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="复检背景值" prop="reInspectionBackgroundValue">
        <a-input v-model="form.reInspectionBackgroundValue" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="复检室内" prop="reInspectionRoom">
        <a-input v-model="form.reInspectionRoom" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="复检路径" prop="reInspectionPath">
        <a-input v-model="form.reInspectionPath" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="复检每分滴漏数" prop="reInspectionDripNumber">
        <a-input v-model="form.reInspectionDripNumber" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="复检备注" prop="reInspectionMark">
        <a-input v-model="form.reInspectionMark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="状态，1：待维修，2：延时维修，3：待复检/已修复，4：已复检" prop="status">
      </a-form-model-item>
      <a-form-model-item label="维修次数" prop="count">
        <a-input v-model="form.count" placeholder="请输入维修次数" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getRepairDetail, addRepairDetail, updateRepairDetail } from '@/api/business/repairDetail'
import { mapGetters } from 'vuex'

export default {
  name: 'CreateForm',
  props: {},
  components: {},
  computed: {
    ...mapGetters(['isEnterpriseOrStaff', 'enterpriseId', 'deptId'])
  },
  data() {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        enterpriseId: null,

        ledgerId: null,

        quarter: null,

        sealingPointId: null,

        repairer: null,

        repairTime: null,

        repairMeasure: null,

        delayHandleUser: null,

        delayHandleTime: null,

        delayReason: null,

        delayExpectedRepairTime: null,

        isInspection: null,

        isLeakage: null,

        inspector: null,

        inspectionTime: null,

        inspectionDevice: null,

        inspectionValue: null,

        inspectionLeakagePlace: null,

        inspectionBackgroundValue: null,

        inspectionRoom: null,

        inspectionPath: null,

        inspectionDripNumber: null,

        inspectionMark: null,

        reInspector: null,

        reInspectionTime: null,

        reInspectionDevice: null,

        reInspectionValue: null,

        reInspectionBackgroundValue: null,

        reInspectionRoom: null,

        reInspectionPath: null,

        reInspectionDripNumber: null,

        reInspectionMark: null,

        status: 0,

        count: null,

        createTime: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        enterpriseId: [
          { required: true, message: '企业ID不能为空', trigger: 'blur' }
        ],
        ledgerId: [
          { required: true, message: '台账ID不能为空', trigger: 'blur' }
        ],
        quarter: [
          { required: true, message: '季度，1：1季度，2：2季度，3：3季度，4：4季度不能为空', trigger: 'blur' }
        ],
        sealingPointId: [
          { required: true, message: '密封点分类ID不能为空', trigger: 'blur' }
        ],
        status: [
          { required: true, message: '状态，1：待维修，2：延时维修，3：待复检/已修复，4：已复检不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {},
  created() {
  },
  watch: {},
  mounted() {
  },
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        enterpriseId: null,
        ledgerId: null,
        quarter: null,
        sealingPointId: null,
        repairer: null,
        repairTime: null,
        repairMeasure: null,
        delayHandleUser: null,
        delayHandleTime: null,
        delayReason: null,
        delayExpectedRepairTime: null,
        isInspection: null,
        isLeakage: null,
        inspector: null,
        inspectionTime: null,
        inspectionDevice: null,
        inspectionValue: null,
        inspectionLeakagePlace: null,
        inspectionBackgroundValue: null,
        inspectionRoom: null,
        inspectionPath: null,
        inspectionDripNumber: null,
        inspectionMark: null,
        reInspector: null,
        reInspectionTime: null,
        reInspectionDevice: null,
        reInspectionValue: null,
        reInspectionBackgroundValue: null,
        reInspectionRoom: null,
        reInspectionPath: null,
        reInspectionDripNumber: null,
        reInspectionMark: null,
        status: 0,
        count: null,
        createTime: null
      }
      this.$nextTick(() => {
        this.$refs.form && this.$refs.form.resetFields()
      })
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getRepairDetail({ 'id': id }).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.isEnterpriseOrStaff) {
            this.form.enterpriseId = this.enterpriseId
          }
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateRepairDetail(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addRepairDetail(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
