<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form"  :rules="rules">
      <a-form-model-item label="企业ID" prop="enterpriseId" >
        <a-input v-model="form.enterpriseId" placeholder="请输入企业ID" />
      </a-form-model-item>
      <a-form-model-item label="装置名称" prop="deviceName" >
        <a-input v-model="form.deviceName" placeholder="请输入装置名称" />
      </a-form-model-item>
      <a-form-model-item label="装置编码-区域" prop="deviceCode" >
        <a-input v-model="form.deviceCode" placeholder="请输入装置编码-区域" />
      </a-form-model-item>
      <a-form-model-item label="运行设备" prop="runningEquipment" >
        <a-input v-model="form.runningEquipment" placeholder="请输入运行设备" />
      </a-form-model-item>
      <a-form-model-item label="产品流" prop="productFlow" >
        <a-input v-model="form.productFlow" placeholder="请输入产品流" />
      </a-form-model-item>
      <a-form-model-item label="物料状态" prop="materialStatus" >
      </a-form-model-item>
      <a-form-model-item label="主参" prop="mainGinseng" >
        <a-input v-model="form.mainGinseng" placeholder="请输入主参" />
      </a-form-model-item>
      <a-form-model-item label="标签" prop="label" >
        <a-input v-model="form.label" placeholder="请输入标签" />
      </a-form-model-item>
      <a-form-model-item label="方向" prop="direction" >
        <a-input v-model="form.direction" placeholder="请输入方向" />
      </a-form-model-item>
      <a-form-model-item label="距离" prop="distance" >
        <a-input v-model="form.distance" placeholder="请输入距离" />
      </a-form-model-item>
      <a-form-model-item label="楼层" prop="floor" >
        <a-input v-model="form.floor" placeholder="请输入楼层" />
      </a-form-model-item>
      <a-form-model-item label="保温" prop="heatPreservation" >
        <a-input v-model="form.heatPreservation" placeholder="请输入保温" />
      </a-form-model-item>
      <a-form-model-item label="尺寸" prop="size" >
        <a-input v-model="form.size" placeholder="请输入尺寸" />
      </a-form-model-item>
      <a-form-model-item label="检测最小停留时间" prop="detectMinimumDwellTime" >
        <a-input v-model="form.detectMinimumDwellTime" placeholder="请输入检测最小停留时间" />
      </a-form-model-item>
      <a-form-model-item label="描述" prop="describe" >
        <a-input v-model="form.describe" placeholder="请输入描述" />
      </a-form-model-item>
      <a-form-model-item label="组件类型" prop="componentType" >
      </a-form-model-item>
      <a-form-model-item label="组件子类型" prop="componentSubtype" >
      </a-form-model-item>
      <a-form-model-item label="扩展号" prop="extensionNumber" >
        <a-input v-model="form.extensionNumber" placeholder="请输入扩展号" />
      </a-form-model-item>
      <a-form-model-item label="群组编码" prop="groupCode" >
        <a-input v-model="form.groupCode" placeholder="请输入群组编码" />
      </a-form-model-item>
      <a-form-model-item label="泄漏阈值" prop="leakageThreshold" >
        <a-input v-model="form.leakageThreshold" placeholder="请输入泄漏阈值" />
      </a-form-model-item>
      <a-form-model-item label="检测配件" prop="testingAccessories" >
        <a-input v-model="form.testingAccessories" placeholder="请输入检测配件" />
      </a-form-model-item>
      <a-form-model-item label="难于检测" prop="difficultDetection" >
        <a-input v-model="form.difficultDetection" placeholder="请输入难于检测" />
      </a-form-model-item>
      <a-form-model-item label="难于检测原因" prop="difficultDetectionReason" >
        <a-input v-model="form.difficultDetectionReason" placeholder="请输入难于检测原因" />
      </a-form-model-item>
      <a-form-model-item label="图片编码" prop="imageCode" >
        <a-input v-model="form.imageCode" placeholder="请输入图片编码" />
      </a-form-model-item>
      <a-form-model-item label="图号" prop="drawingNo" >
        <a-input v-model="form.drawingNo" placeholder="请输入图号" />
      </a-form-model-item>
      <a-form-model-item label="变更管理ID" prop="changeManagementId" >
        <a-input v-model="form.changeManagementId" placeholder="请输入变更管理ID" />
      </a-form-model-item>
      <a-form-model-item label="年运行时间/小时" prop="annualOperatingTime" >
        <a-input v-model="form.annualOperatingTime" placeholder="请输入年运行时间/小时" />
      </a-form-model-item>
      <a-form-model-item label="建档时间" prop="putOnRecordTime" >
        <a-input v-model="form.putOnRecordTime" placeholder="请输入建档时间" />
      </a-form-model-item>
      <a-form-model-item label="开工时间" prop="startTime" >
        <a-input v-model="form.startTime" placeholder="请输入开工时间" />
      </a-form-model-item>
      <a-form-model-item label="险于检测" prop="riskDetection" >
        <a-input v-model="form.riskDetection" placeholder="请输入险于检测" />
      </a-form-model-item>
      <a-form-model-item label="险于检测原因" prop="riskDetectionReason" >
        <a-input v-model="form.riskDetectionReason" placeholder="请输入险于检测原因" />
      </a-form-model-item>
      <a-form-model-item label="密封材质" prop="sealingMaterial" >
        <a-input v-model="form.sealingMaterial" placeholder="请输入密封材质" />
      </a-form-model-item>
      <a-form-model-item label="室内" prop="indoor" >
        <a-input v-model="form.indoor" placeholder="请输入室内" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getLedger, addLedger, updateLedger } from '@/api/business/ledger'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        enterpriseId: null,

        deviceName: null,

        deviceCode: null,

        runningEquipment: null,

        productFlow: null,

        materialStatus: '0',

        mainGinseng: null,

        label: null,

        direction: null,

        distance: null,

        floor: null,

        heatPreservation: null,

        size: null,

        detectMinimumDwellTime: null,

        describe: null,

        componentType: null,

        componentSubtype: null,

        extensionNumber: null,

        groupCode: null,

        leakageThreshold: null,

        testingAccessories: null,

        difficultDetection: null,

        difficultDetectionReason: null,

        imageCode: null,

        drawingNo: null,

        changeManagementId: null,

        annualOperatingTime: null,

        putOnRecordTime: null,

        startTime: null,

        riskDetection: null,

        riskDetectionReason: null,

        sealingMaterial: null,

        indoor: null,

        createTime: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        enterpriseId: [
          { required: true, message: '企业ID不能为空', trigger: 'blur' }
        ],
        deviceName: [
          { required: true, message: '装置名称不能为空', trigger: 'blur' }
        ],
        deviceCode: [
          { required: true, message: '装置编码-区域不能为空', trigger: 'blur' }
        ],
        runningEquipment: [
          { required: true, message: '运行设备不能为空', trigger: 'blur' }
        ],
        productFlow: [
          { required: true, message: '产品流不能为空', trigger: 'blur' }
        ],
        materialStatus: [
          { required: true, message: '物料状态不能为空', trigger: 'blur' }
        ],
        mainGinseng: [
          { required: true, message: '主参不能为空', trigger: 'blur' }
        ],
        label: [
          { required: true, message: '标签不能为空', trigger: 'blur' }
        ],
        direction: [
          { required: true, message: '方向不能为空', trigger: 'blur' }
        ],
        distance: [
          { required: true, message: '距离不能为空', trigger: 'blur' }
        ],
        floor: [
          { required: true, message: '楼层不能为空', trigger: 'blur' }
        ],
        heatPreservation: [
          { required: true, message: '保温不能为空', trigger: 'blur' }
        ],
        size: [
          { required: true, message: '尺寸不能为空', trigger: 'blur' }
        ],
        detectMinimumDwellTime: [
          { required: true, message: '检测最小停留时间不能为空', trigger: 'blur' }
        ],
        describe: [
          { required: true, message: '描述不能为空', trigger: 'blur' }
        ],
        componentType: [
          { required: true, message: '组件类型不能为空', trigger: 'change' }
        ],
        componentSubtype: [
          { required: true, message: '组件子类型不能为空', trigger: 'change' }
        ],
        extensionNumber: [
          { required: true, message: '扩展号不能为空', trigger: 'blur' }
        ],
        groupCode: [
          { required: true, message: '群组编码不能为空', trigger: 'blur' }
        ],
        leakageThreshold: [
          { required: true, message: '泄漏阈值不能为空', trigger: 'blur' }
        ],
        testingAccessories: [
          { required: true, message: '检测配件不能为空', trigger: 'blur' }
        ],
        difficultDetection: [
          { required: true, message: '难于检测不能为空', trigger: 'blur' }
        ],
        difficultDetectionReason: [
          { required: true, message: '难于检测原因不能为空', trigger: 'blur' }
        ],
        imageCode: [
          { required: true, message: '图片编码不能为空', trigger: 'blur' }
        ],
        drawingNo: [
          { required: true, message: '图号不能为空', trigger: 'blur' }
        ],
        changeManagementId: [
          { required: true, message: '变更管理ID不能为空', trigger: 'blur' }
        ],
        annualOperatingTime: [
          { required: true, message: '年运行时间/小时不能为空', trigger: 'blur' }
        ],
        putOnRecordTime: [
          { required: true, message: '建档时间不能为空', trigger: 'blur' }
        ],
        startTime: [
          { required: true, message: '开工时间不能为空', trigger: 'blur' }
        ],
        riskDetection: [
          { required: true, message: '险于检测不能为空', trigger: 'blur' }
        ],
        riskDetectionReason: [
          { required: true, message: '险于检测原因不能为空', trigger: 'blur' }
        ],
        sealingMaterial: [
          { required: true, message: '密封材质不能为空', trigger: 'blur' }
        ],
        indoor: [
          { required: true, message: '室内不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        enterpriseId: null,
        deviceName: null,
        deviceCode: null,
        runningEquipment: null,
        productFlow: null,
        materialStatus: '0',
        mainGinseng: null,
        label: null,
        direction: null,
        distance: null,
        floor: null,
        heatPreservation: null,
        size: null,
        detectMinimumDwellTime: null,
        describe: null,
        componentType: null,
        componentSubtype: null,
        extensionNumber: null,
        groupCode: null,
        leakageThreshold: null,
        testingAccessories: null,
        difficultDetection: null,
        difficultDetectionReason: null,
        imageCode: null,
        drawingNo: null,
        changeManagementId: null,
        annualOperatingTime: null,
        putOnRecordTime: null,
        startTime: null,
        riskDetection: null,
        riskDetectionReason: null,
        sealingMaterial: null,
        indoor: null,
        createTime: null
      }
      this.$nextTick(() => {
        this.$refs.form && this.$refs.form.resetFields()
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getLedger({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateLedger(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addLedger(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
