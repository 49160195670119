<template>
  <a-drawer width="75%" :visible="open" @close="onClose" :closable="false" body-style="padding: 65px 24px 50px 24px;">
    <div class="herder">
      <span class="title">任务检测明细</span>
      <div class="stat-list">
        <span class="item">总检测点位：{{ taskData.total }}</span>
        <span class="item">已检测点位：{{ taskData.inspectionCount }}</span>
        <span class="item">未检测点位：{{ (taskData.total - taskData.inspectionCount) }}</span>
        <span class="item">泄漏点位：<span class="red">{{ taskData.leakageCount }}</span></span>
      </div>
    </div>

    <!-- 条件搜索 -->
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="6" :sm="24">
            <a-form-item label="检测ID" prop="id">
              <a-input v-model="queryParam.id" placeholder="请输入" allow-clear />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="是否检测" prop="isInspection">
              <a-select placeholder="请选择" v-model="queryParam.isInspection" style="width: 100%" allow-clear>
                <a-select-option :value="true">是</a-select-option>
                <a-select-option :value="false">否</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="是否泄漏" prop="isLeakage">
              <a-select placeholder="请选择" v-model="queryParam.isLeakage" style="width: 100%" allow-clear>
                <a-select-option :value="true">是</a-select-option>
                <a-select-option :value="false">否</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="!advanced && 6 || 24" :sm="24">
            <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
              <a-button type="primary" @click="handleQuery">搜索</a-button>
              <a-button style="margin-left: 8px" @click="resetQuery">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <!-- 操作 -->
    <div class="table-operations">
      <a-button type="danger" :disabled="multiple" @click="handleDelete">
        删除
      </a-button>
    </div>
    <!-- 数据展示 -->
    <a-table
      :loading="loading"
      :size="tableSize"
      rowKey="id"
      :columns="columns"
      :data-source="list"
      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      :pagination="false"
      :bordered="tableBordered"
      :scroll="{ x: true }"
      :rowClassName="(record,index)=>(record.isLeakage?'is-leakage':'')"
    >
      <div slot="imageCode" slot-scope="text, record">
        <image-code-column :image-code="record.ledger.imageCode" />
      </div>
      <span slot="isInspection" slot-scope="text, record">{{ record.isInspection ? '是' : '否' }}</span>
      <span slot="isLeakage" slot-scope="text, record">{{ record.isLeakage ? '是' : '否' }}</span>
      <span slot="inspectionTime" slot-scope="text, record">{{ parseTime(new Date(record.inspectionTime), '{y}/{m}/{d} {h}:{i}') }}</span>
    </a-table>
    <!-- 分页 -->
    <a-pagination
      class="ant-table-pagination"
      show-size-changer
      show-quick-jumper
      :current="queryParam.pageNum"
      :total="total"
      :page-size="queryParam.pageSize"
      :showTotal="total => `共 ${total} 条`"
      @showSizeChange="onShowSizeChange"
      @change="changeSize"
    />
    <div class="bottom-control">
      <a-button @click="cancel">关闭</a-button>
    </div>
  </a-drawer>
</template>

<script>

import { delRepairDetail, pageRepairDetail } from '@/api/business/repairDetail'
import { tableMixin } from '@/store/table-mixin'
import SelectTag from '@/components/SelectTag/index.vue'
import ImageCodeColumn from '@/components/ImageCodeColumn/index.vue'

export default {
  name: 'TaskDetailDrawer',
  props: {
    deleted: {
      type: Boolean,
      default: false
    }
  },
  components: { ImageCodeColumn, SelectTag },
  mixins: [tableMixin],
  data() {
    return {
      taskId: null,
      open: false,
      taskData: {},
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        id: null,
        taskId: null,
        enterpriseId: null,
        ledgerId: null,
        quarter: null,
        sealingPointId: null,
        repairer: null,
        repairTime: null,
        repairMeasure: null,
        delayHandleUser: null,
        delayHandleTime: null,
        delayReason: null,
        delayExpectedRepairTime: null,
        isInspection: undefined,
        isLeakage: undefined,
        inspector: null,
        inspectionTime: null,
        inspectionDevice: null,
        inspectionValue: null,
        inspectionLeakagePlace: null,
        inspectionBackgroundValue: null,
        inspectionRoom: null,
        inspectionPath: null,
        inspectionDripNumber: null,
        inspectionMark: null,
        reInspector: null,
        reInspectionTime: null,
        reInspectionDevice: null,
        reInspectionValue: null,
        reInspectionBackgroundValue: null,
        reInspectionRoom: null,
        reInspectionPath: null,
        reInspectionDripNumber: null,
        reInspectionMark: null,
        status: null,
        count: null,
        deleted: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '检测ID',
          dataIndex: 'id',
          ellipsis: true,
          align: 'center',
          fixed: 'left',
          width: 100
        },
        {
          title: '是否检测',
          dataIndex: 'isInspection',
          scopedSlots: { customRender: 'isInspection' },
          ellipsis: true,
          align: 'center',
          width: 80
        },
        {
          title: '装置名称',
          dataIndex: 'ledger.deviceName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '装置编码-区域',
          dataIndex: 'ledger.deviceCode',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '运行设备',
          dataIndex: 'ledger.runningEquipment',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '标签',
          dataIndex: 'ledger.label',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '位置描述',
          dataIndex: 'ledger.describe',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '组件类型',
          dataIndex: 'ledger.componentType',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '组件子类型',
          dataIndex: 'ledger.componentSubtype',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '泄漏阈值',
          dataIndex: 'ledger.leakageThreshold',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '检测最小停留时间（秒）',
          dataIndex: 'ledger.detectMinimumDwellTime',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '图片编码',
          dataIndex: 'ledger.imageCode',
          ellipsis: true,
          align: 'center',
          scopedSlots: { customRender: 'imageCode' }
        },
        {
          title: '检测人员',
          dataIndex: 'inspector',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '检测日期',
          dataIndex: 'inspectionTime',
          scopedSlots: { customRender: 'inspectionTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '检测仪器',
          dataIndex: 'inspectionDevice',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '检测值',
          dataIndex: 'inspectionValue',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '是否泄漏',
          dataIndex: 'isLeakage',
          scopedSlots: { customRender: 'isLeakage' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '泄漏部位',
          dataIndex: 'inspectionLeakagePlace',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '背景值',
          dataIndex: 'inspectionBackgroundValue',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '室内',
          dataIndex: 'inspectionRoom',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '检测路径',
          dataIndex: 'inspectionPath',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '每分滴漏数',
          dataIndex: 'inspectionDripNumber',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '备注',
          dataIndex: 'inspectionMark',
          ellipsis: true,
          align: 'center'
        }
      ]
    }
  },
  filters: {},
  created() {
  },
  computed: {},
  watch: {},
  mounted() {
  },
  methods: {
    onClose() {
      this.reset()
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.reset()
      this.open = false
    },
    reset() {
      this.queryParam = {
        id: null,
        taskId: null,
        enterpriseId: null,
        ledgerId: null,
        quarter: null,
        sealingPointId: null,
        repairer: null,
        repairTime: null,
        repairMeasure: null,
        delayHandleUser: null,
        delayHandleTime: null,
        delayReason: null,
        delayExpectedRepairTime: null,
        isInspection: undefined,
        isLeakage: undefined,
        inspector: null,
        inspectionTime: null,
        inspectionDevice: null,
        inspectionValue: null,
        inspectionLeakagePlace: null,
        inspectionBackgroundValue: null,
        inspectionRoom: null,
        inspectionPath: null,
        inspectionDripNumber: null,
        inspectionMark: null,
        reInspector: null,
        reInspectionTime: null,
        reInspectionDevice: null,
        reInspectionValue: null,
        reInspectionBackgroundValue: null,
        reInspectionRoom: null,
        reInspectionPath: null,
        reInspectionDripNumber: null,
        reInspectionMark: null,
        status: null,
        count: null,
        pageNum: 1,
        pageSize: 10
      }
      this.list = []
      this.ids = []
      this.selectedRowKeys = []
      this.selectedRows = []
      this.single = true
      this.multiple = true
      this.taskId = undefined
    },
    /** 打开按钮 */
    openDrawer(row) {
      this.reset()
      this.taskData = row
      this.open = true
      this.taskId = row.id
      this.getList()
    },
    /** 查询维修明细管理列表 */
    getList() {
      this.loading = true
      this.queryParam.taskId = this.taskId
      console.log('this.deleted : ', this.deleted)
      this.queryParam.deleted = this.deleted
      pageRepairDetail(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        enterpriseId: undefined,
        ledgerId: undefined,
        quarter: undefined,
        sealingPointId: undefined,
        repairer: undefined,
        repairTime: undefined,
        repairMeasure: undefined,
        delayHandleUser: undefined,
        delayHandleTime: undefined,
        delayReason: undefined,
        delayExpectedRepairTime: undefined,
        isInspection: undefined,
        isLeakage: undefined,
        inspector: undefined,
        inspectionTime: undefined,
        inspectionDevice: undefined,
        inspectionValue: undefined,
        inspectionLeakagePlace: undefined,
        inspectionBackgroundValue: undefined,
        inspectionRoom: undefined,
        inspectionPath: undefined,
        inspectionDripNumber: undefined,
        inspectionMark: undefined,
        reInspector: undefined,
        reInspectionTime: undefined,
        reInspectionDevice: undefined,
        reInspectionValue: undefined,
        reInspectionBackgroundValue: undefined,
        reInspectionRoom: undefined,
        reInspectionPath: undefined,
        reInspectionDripNumber: undefined,
        reInspectionMark: undefined,
        status: undefined,
        count: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          const data = []
          that.ids.map(id => data.push({ 'id': id }))
          return delRepairDetail(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
              that.$emit('delete')
            })
        },
        onCancel() {
        }
      })
    }
  }
}
</script>
<style>
.is-leakage {
  color: red;
}
</style>
<style lang="less" scoped>
.herder {
  width: 100%;
  height: 40px;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;

  .title {
    font-size: 18px;
    font-weight: bold;
    margin-left: 20px;
  }

  .stat-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 100px;

    .item {
      margin-left: 24px;
    }

    .red {
      color: red;
    }
  }
}
</style>
