<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24" v-if="!isEnterpriseOrStaff">
              <a-form-item label="企业名称" prop="enterpriseId">
                <a-select placeholder="请选择" v-model="queryParam.enterpriseId" style="width: 100%" allow-clear>
                  <a-select-option v-for="(d, index) in enterpriseList" :key="index" :value="d.key">{{ d.value }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
<!--            <a-col :md="8" :sm="24">-->
<!--              <a-form-item label="台账ID" prop="ledgerId">-->
<!--                <a-input v-model="queryParam.ledgerId" placeholder="请输入台账ID" allow-clear />-->
<!--              </a-form-item>-->
<!--            </a-col>-->
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="季度" prop="quarter">
                  <a-select placeholder="请选择" v-model="queryParam.quarter" style="width: 100%" allow-clear>
                    <a-select-option :value="1">1季度</a-select-option>
                    <a-select-option :value="2">2季度</a-select-option>
                    <a-select-option :value="3">3季度</a-select-option>
                    <a-select-option :value="4">4季度</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="密封点分类" prop="sealingPointId">
                  <a-select placeholder="请选择" v-model="queryParam.sealingPointId" style="width: 100%" allow-clear>
                    <a-select-option v-for="(d, index) in sealingPointDictList" :key="index" :value="d.key">{{ d.value }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
<!--              <a-col :md="8" :sm="24">-->
<!--                <a-form-item label="延时修复办理人" prop="delayHandleUser">-->
<!--                  <a-input v-model="queryParam.delayHandleUser" placeholder="请输入延时修复办理人" allow-clear />-->
<!--                </a-form-item>-->
<!--              </a-col>-->
<!--              <a-col :md="8" :sm="24">-->
<!--                <a-form-item label="延时修复办理日期" prop="delayHandleTime">-->
<!--                  <a-input v-model="queryParam.delayHandleTime" placeholder="请输入延时修复办理日期" allow-clear />-->
<!--                </a-form-item>-->
<!--              </a-col>-->
<!--              <a-col :md="8" :sm="24">-->
<!--                <a-form-item label="延时修复原因" prop="delayReason">-->
<!--                  <a-input v-model="queryParam.delayReason" placeholder="请输入延时修复原因" allow-clear />-->
<!--                </a-form-item>-->
<!--              </a-col>-->
<!--              <a-col :md="8" :sm="24">-->
<!--                <a-form-item label="延时修复预计维修日期" prop="delayExpectedRepairTime">-->
<!--                  <a-input v-model="queryParam.delayExpectedRepairTime" placeholder="请输入延时修复预计维修日期" allow-clear />-->
<!--                </a-form-item>-->
<!--              </a-col>-->
<!--              <a-col :md="8" :sm="24">-->
<!--                <a-form-item label="是否检测" prop="isInspection">-->
<!--                  <a-input v-model="queryParam.isInspection" placeholder="请输入是否检测" allow-clear />-->
<!--                </a-form-item>-->
<!--              </a-col>-->
<!--              <a-col :md="8" :sm="24">-->
<!--                <a-form-item label="是否泄漏" prop="isLeakage">-->
<!--                  <a-select placeholder="请选择" v-model="queryParam.isLeakage" style="width: 100%" allow-clear>-->
<!--                    <a-select-option :value="1">是</a-select-option>-->
<!--                    <a-select-option :value="0">否</a-select-option>-->
<!--                  </a-select>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
              <a-col :md="8" :sm="24">
                <a-form-item label="维修次数" prop="count">
                  <a-input v-model="queryParam.count" placeholder="请输入维修次数" allow-clear />
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="!advanced && 8 || 16" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
<!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['business:repair/detail:add']">-->
<!--          <a-icon type="plus" />-->
<!--          新增-->
<!--        </a-button>-->
<!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['business:repair/detail:edit']">-->
<!--          <a-icon type="edit" />-->
<!--          修改-->
<!--        </a-button>-->
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['business:repair/detail:remove']">
          <a-icon type="delete" />
          删除
        </a-button>
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['business:repair/detail:export']">-->
<!--          <a-icon type="download" />-->
<!--          导出-->
<!--        </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
        :scroll="{ x: true }"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="repairTime" slot-scope="text, record">
          {{ parseTime(record.repairTime) }}
        </span>
        <span slot="reInspectionTime" slot-scope="text, record">
          {{ parseTime(record.reInspectionTime) }}
        </span>
        <span slot="inspectionTime" slot-scope="text, record">
          {{ parseTime(record.inspectionTime) }}
        </span>
        <span slot="repairTime" slot-scope="text, record">
          {{ parseTime(record.repairTime) }}
        </span>
        <span slot="sealingPointId" slot-scope="text, record">
          <select-tag :options="sealingPointDictList" :value="record.sealingPointId" />
        </span>
        <span slot="status" slot-scope="text, record">
          <select-tag :options="statusList" :value="record.status" />
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-space>
<!--            <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['business:repair/detail:edit']">修改</a>-->
            <a @click="handleDelete(record)" v-hasPermi="['business:repair/detail:remove']">删除</a>
          </a-space>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { delRepairDetail, pageRepairDetail } from '@/api/business/repairDetail'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import { enterpriseNameList } from '@/api/system/user'
import { selectListSealingPointDict } from '@/api/business/sealingPointDict'
import { mapGetters } from 'vuex'
import SelectTag from "@/components/SelectTag/index.vue";
import {parseTime} from "../../../utils/ruoyi";

export default {
  name: 'RepairDetail',
  components: {
    SelectTag,
    CreateForm
  },
  mixins: [tableMixin],
  computed: {
    ...mapGetters(['isEnterpriseOrStaff', 'enterpriseId', 'deptId'])
  },
  data() {
    return {
      enterpriseList: [],
      sealingPointDictList: [],
      statusList: [],
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        enterpriseId: null,
        ledgerId: null,
        quarter: null,
        sealingPointId: null,
        repairer: null,
        repairTime: null,
        repairMeasure: null,
        delayHandleUser: null,
        delayHandleTime: null,
        delayReason: null,
        delayExpectedRepairTime: null,
        isInspection: null,
        isLeakage: null,
        inspector: null,
        inspectionTime: null,
        inspectionDevice: null,
        inspectionValue: null,
        inspectionLeakagePlace: null,
        inspectionBackgroundValue: null,
        inspectionRoom: null,
        inspectionPath: null,
        inspectionDripNumber: null,
        inspectionMark: null,
        reInspector: null,
        reInspectionTime: null,
        reInspectionDevice: null,
        reInspectionValue: null,
        reInspectionBackgroundValue: null,
        reInspectionRoom: null,
        reInspectionPath: null,
        reInspectionDripNumber: null,
        reInspectionMark: null,
        status: null,
        count: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '检测ID',
          dataIndex: 'id',
          ellipsis: true,
          align: 'center',
          fixed: 'left',
          width: 100
        },
        // {
        //   title: '企业ID',
        //   dataIndex: 'enterpriseId',
        //   ellipsis: true,
        //   align: 'center',
        //   width: 100
        // },
        // {
        //   title: '台账ID',
        //   dataIndex: 'ledgerId',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '季度',
          dataIndex: 'quarter',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '密封点分类',
          dataIndex: 'sealingPointId',
          ellipsis: true,
          scopedSlots: { customRender: 'sealingPointId' },
          align: 'center'
        },
        {
          title: '维修人',
          dataIndex: 'repairer',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '维修时间',
          dataIndex: 'repairTime',
          scopedSlots: { customRender: 'repairTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '维修措施',
          dataIndex: 'repairMeasure',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '延时修复办理人',
          dataIndex: 'delayHandleUser',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '延时修复办理日期',
          dataIndex: 'delayHandleTime',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '延时修复原因',
          dataIndex: 'delayReason',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '延时修复预计维修日期',
          dataIndex: 'delayExpectedRepairTime',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '是否检测',
          dataIndex: 'isInspection',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '是否泄漏',
          dataIndex: 'isLeakage',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '检测人员',
          dataIndex: 'inspector',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '检测日期',
          dataIndex: 'inspectionTime',
          scopedSlots: { customRender: 'inspectionTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '检测仪器',
          dataIndex: 'inspectionDevice',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '检测值',
          dataIndex: 'inspectionValue',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '泄漏部位',
          dataIndex: 'inspectionLeakagePlace',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '背景值',
          dataIndex: 'inspectionBackgroundValue',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '室内',
          dataIndex: 'inspectionRoom',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '检测路径',
          dataIndex: 'inspectionPath',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '每分滴漏数',
          dataIndex: 'inspectionDripNumber',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '检测备注',
          dataIndex: 'inspectionMark',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '复检人员',
          dataIndex: 'reInspector',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '复检日期',
          dataIndex: 'reInspectionTime',
          scopedSlots: { customRender: 'reInspectionTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '复检仪器',
          dataIndex: 'reInspectionDevice',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '复检值',
          dataIndex: 'reInspectionValue',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '复检背景值',
          dataIndex: 'reInspectionBackgroundValue',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '复检室内',
          dataIndex: 'reInspectionRoom',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '复检路径',
          dataIndex: 'reInspectionPath',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '复检每分滴漏数',
          dataIndex: 'reInspectionDripNumber',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '复检备注',
          dataIndex: 'reInspectionMark',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '维修次数',
          dataIndex: 'count',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '插入时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 100,
          scopedSlots: { customRender: 'operation' },
          align: 'center',
          fixed: 'right'
        }
      ]
    }
  },
  filters: {},
  created() {
    this.getList()
    enterpriseNameList().then(res => {
      this.enterpriseList = res.data
    })
    selectListSealingPointDict().then(res => {
      this.sealingPointDictList = res.data
    })
    //，1：待维修，2：延时维修，3：待复检/已修复，4：已复检
    this.statusList = [
      {
      key: '1',
      value: '待维修'
      },
      {
        key: '2',
        value: '延时维修'
      },
      {
        key: '3',
        value: '已维修'
      },
      {
        key: '4',
        value: '已复检'
      },
    ]
  },
  watch: {},
  methods: {
    parseTime,
    /** 查询维修明细管理列表 */
    getList() {
      this.loading = true
      this.queryParam.isLeakage = 1;
      if (this.isEnterpriseOrStaff) {
        this.queryParam.enterpriseId = this.enterpriseId
      }
      pageRepairDetail(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        enterpriseId: undefined,
        ledgerId: undefined,
        quarter: undefined,
        sealingPointId: undefined,
        repairer: undefined,
        repairTime: undefined,
        repairMeasure: undefined,
        delayHandleUser: undefined,
        delayHandleTime: undefined,
        delayReason: undefined,
        delayExpectedRepairTime: undefined,
        isInspection: undefined,
        isLeakage: undefined,
        inspector: undefined,
        inspectionTime: undefined,
        inspectionDevice: undefined,
        inspectionValue: undefined,
        inspectionLeakagePlace: undefined,
        inspectionBackgroundValue: undefined,
        inspectionRoom: undefined,
        inspectionPath: undefined,
        inspectionDripNumber: undefined,
        inspectionMark: undefined,
        reInspector: undefined,
        reInspectionTime: undefined,
        reInspectionDevice: undefined,
        reInspectionValue: undefined,
        reInspectionBackgroundValue: undefined,
        reInspectionRoom: undefined,
        reInspectionPath: undefined,
        reInspectionDripNumber: undefined,
        reInspectionMark: undefined,
        status: undefined,
        count: undefined,
        deleted: false,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          let data = []
          that.ids.map(id => data.push({ 'id': id }))
          return delRepairDetail(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
            })
        },
        onCancel() {
        }
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          that.download('business/repair/detail/export', {
            ...that.queryParam
          }, `维修明细管理_${new Date().getTime()}.xlsx`)
        },
        onCancel() {
        }
      })
    }
  }
}
</script>
