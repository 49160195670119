import request from '@/utils/request'


// 查询检测仪器管理列表
export function listDetector(query) {
  return request({
    url: '/business/detector/list',
    method: 'get',
    params: query
  })
}

// 查询检测仪器管理分页
export function pageDetector(query) {
  return request({
    url: '/business/detector/page',
    method: 'get',
    params: query
  })
}

// 查询检测仪器管理详细
export function getDetector(data) {
  return request({
    url: '/business/detector/detail',
    method: 'get',
    params: data
  })
}

// 新增检测仪器管理
export function addDetector(data) {
  return request({
    url: '/business/detector/add',
    method: 'post',
    data: data
  })
}

// 修改检测仪器管理
export function updateDetector(data) {
  return request({
    url: '/business/detector/edit',
    method: 'post',
    data: data
  })
}

// 删除检测仪器管理
export function delDetector(data) {
  return request({
    url: '/business/detector/delete',
    method: 'post',
    data: data
  })
}
