<template>
  <div id="antdVue" class="antdVue" :style="themeColorInit(themeColorConfig)">
      <!---栅格布局 布局开始-->
      <a-row class="benben-grid  heartcheckManagementpicChecklist_flex_0">


          <a-col :span="24">
              <a-form-model ref="formRef1686814800557" class="benben-flex-form heartcheckManagementpicChecklist_fd0_0_c0" label-align="right" layout="horizontal" :label-col="{span:8,offset:0}" :wrapper-col="{span:16,offset:0}">
                  <a-row class="benben-grid width_fullscreen heartcheckManagementpicChecklist_fd0_0_c0_c0" layout="4:4:4:4:4:4" :gutter="[8,8]">

                      <a-col :span="4" class="flex-sub">
                          <a-form-model-item need="false" :rules="[]" :colon="true" label="">
                              <a-input class="heartcheckManagementpicChecklist_fd0_0_c0_c0_c0_c0" :max-length="-1" placeholder="审核类型" :allow-clear="true">
                              </a-input>
                          </a-form-model-item>

                      </a-col>



                      <a-col :span="4" class="flex-sub">
                          <a-form-model-item need="false" :rules="[]" :colon="true" label="">
                              <simple-select :disabled="false" mode="default" :options="fieldName_8123" :field-names='{"label":"name","value":"aid","children":"child"}'>

                              </simple-select>
                          </a-form-model-item>

                      </a-col>



                      <a-col :span="4" class="flex-sub">
                          <a-form-model-item need="false" :rules="[]" :colon="true" label="">
                              <a-input class="heartcheckManagementpicChecklist_fd0_0_c0_c0_c2_c0" :max-length="-1" placeholder="请输入" :allow-clear="true">
                              </a-input>
                          </a-form-model-item>

                      </a-col>



                      <a-col :span="4" class="flex-sub">
                          <a-form-model-item need="false" :rules="[]" :colon="false" label="">
                              <simple-select :disabled="false" mode="default" :options="fieldName_4661" :field-names='{"label":"name","value":"aid","children":"child"}'>

                              </simple-select>
                          </a-form-model-item>

                      </a-col>



                      <a-col :span="4" class="flex-sub">
                          <a-input class='benben-flex-input-diy' placeholder="请输入" :max-length="240" size="default" :allow-clear='true'>
                          </a-input>


                      </a-col>



                      <a-col :span="4" class="flex-sub">
                          <a-input class='benben-flex-input-diy' placeholder="请输入" :max-length="240" size="default" :allow-clear='true'>
                          </a-input>


                      </a-col>

                  </a-row>
                  <a-row class="benben-grid width_fullscreen heartcheckManagementpicChecklist_fd0_0_c0_c1" layout="4:4:4:4:4:4" :gutter="[8,8]">

                      <a-col :span="4" class="flex-sub">

                      </a-col>



                      <a-col :span="4" class="flex-sub">

                      </a-col>



                      <a-col :span="4" class="flex-sub">

                      </a-col>



                      <a-col :span="4" class="flex-sub">

                      </a-col>



                      <a-col :span="4" class="flex-sub">

                      </a-col>



                      <a-col :span="4" class="flex-sub">
                          <a-button class="heartcheckManagementpicChecklist_fd0_0_c0_c1_c5_c0">查询</a-button>

                          <a-button>重置</a-button>


                      </a-col>

                  </a-row>
              </a-form-model>

          </a-col>



          <a-col :span="18">
              <a-button class="heartcheckManagementpicChecklist_fd0_1_c0" :disabled="false">批量审核成功</a-button>

              <a-button class="heartcheckManagementpicChecklist_fd0_1_c1" :disabled="false">批量审核失败</a-button>


          </a-col>



          <a-col :span="6">

          </a-col>


      </a-row>
      <!---栅格布局 布局结束-->

      <!---栅格布局 布局开始-->
      <a-row class="benben-grid  heartcheckManagementpicChecklist_flex_1">


          <a-col :span="24">
              <a-row class="benben-grid width_fullscreen heartcheckManagementpicChecklist_fd1_0_c0" layout="24:18:6" :gutter="[8,8]">

                  <a-col :span="24">
                      <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd1_0_c0_c0_c0" isAlone="false">
                          <a-row class="benben-grid width_fullscreen heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0" layout="24:24:24" :gutter="[8,8]">

                              <a-col :span="24" class="flex-sub heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0_c0">
                                  <div class="flex align-center heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0_c0_c0" isAlone="false">
                                      <div class="flex flex-direction flex-wrap align-center heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0_c0_c0_c0" isAlone="false">
                                          <img class='heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0_c0_c0_c0_c0' src="https://zhongben-crm.oss-cn-zhangjiakou.aliyuncs.com/uploads/images/20230506/5e7c7153a10174e892d387190e2259a2.png" />

                                          <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0_c0_c0_c0_c1" isAlone="false">
                                              <a-button class="heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0_c0_c0_c0_c1_c0" :disabled="false">审核成功</a-button>

                                              <a-button class="heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0_c0_c0_c0_c1_c1" :disabled="false">审核失败</a-button>


                                          </div>


                                      </div>

                                      <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0_c0_c0_c1" isAlone="false">
                                          <img class='heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0_c0_c0_c1_c0' src="https://zhongben-crm.oss-cn-zhangjiakou.aliyuncs.com/uploads/images/20211025/1f82a035e1b80980e7764e8bc5094f22.jpg" />

                                          <a-button :disabled="false">审核成功</a-button>

                                          <a-button :disabled="false">审核失败</a-button>


                                      </div>

                                      <div class="flex flex-direction flex-wrap align-center heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0_c0_c0_c2" isAlone="false">
                                          <img class='heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0_c0_c0_c2_c0' src="https://zhongben-crm.oss-cn-zhangjiakou.aliyuncs.com/uploads/images/20230506/1c20c3a73dbd8cdc9efc399e44089bff.png" />

                                          <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0_c0_c0_c2_c1" isAlone="false">
                                              <a-button :disabled="false">按钮</a-button>

                                              <a-button :disabled="false">按钮</a-button>


                                          </div>


                                      </div>

                                      <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0_c0_c0_c3" isAlone="false">
                                          <img class='heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0_c0_c0_c3_c0' src="https://zhongben-crm.oss-cn-zhangjiakou.aliyuncs.com/uploads/images/20211025/1f82a035e1b80980e7764e8bc5094f22.jpg" />

                                          <a-button :disabled="false">按钮</a-button>

                                          <a-button :disabled="false">按钮</a-button>


                                      </div>

                                      <div class="flex flex-direction flex-wrap align-center heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0_c0_c0_c4" isAlone="false">
                                          <img class='heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0_c0_c0_c4_c0' src="https://zhongben-crm.oss-cn-zhangjiakou.aliyuncs.com/uploads/images/20230506/3ef1ea19b9653a35e1827e563f25e63f.png" />


                                          <span class="heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0_c0_c0_c4_c1">采购订单</span>

                                      </div>

                                      <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0_c0_c0_c5" isAlone="false">
                                          <img class='heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0_c0_c0_c5_c0' src="https://zhongben-crm.oss-cn-zhangjiakou.aliyuncs.com/uploads/images/20211025/1f82a035e1b80980e7764e8bc5094f22.jpg" />

                                          <a-button :disabled="false">按钮</a-button>

                                          <a-button :disabled="false">按钮</a-button>


                                      </div>

                                      <div class="flex  flex-wrap align-center heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0_c0_c0_c6" isAlone="false">
                                          <img class='heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0_c0_c0_c6_c0' src="https://zhongben-crm.oss-cn-zhangjiakou.aliyuncs.com/uploads/images/20211025/1f82a035e1b80980e7764e8bc5094f22.jpg" />

                                          <a-button :disabled="false">按钮</a-button>

                                          <a-button :disabled="false">按钮</a-button>


                                      </div>


                                  </div>


                              </a-col>



                              <a-col :span="24" class="flex-sub">
                                  <div class="flex align-center heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0_c1_c0" isAlone="false">
                                      <div class="flex flex-direction flex-wrap align-center heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0_c1_c0_c0" isAlone="false">
                                          <img class='heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0_c1_c0_c0_c0' src="https://zhongben-crm.oss-cn-zhangjiakou.aliyuncs.com/uploads/images/20230506/6a2779243be05fcc7462af3704090647.png" />


                                          <span class="heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0_c1_c0_c0_c1">到票登记</span>

                                      </div>

                                      <div class="flex flex-direction flex-wrap align-center heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0_c1_c0_c1" isAlone="false">
                                          <img class='heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0_c1_c0_c1_c0' src="https://zhongben-crm.oss-cn-zhangjiakou.aliyuncs.com/uploads/images/20230506/ae6a7a59d1a9c701eed683d4fcdbdd94.png" />


                                          <span class="heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0_c1_c0_c1_c1">付款申请</span>

                                      </div>

                                      <div class="flex flex-direction flex-wrap align-center heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0_c1_c0_c2" isAlone="false">
                                          <img class='heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0_c1_c0_c2_c0' src="https://zhongben-crm.oss-cn-zhangjiakou.aliyuncs.com/uploads/images/20230506/f8a5f1877c27a877d44d6c0a1f4d8ea3.png" />


                                          <span class="heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0_c1_c0_c2_c1">付款单据</span>

                                      </div>


                                  </div>


                              </a-col>



                              <a-col :span="24">

                              </a-col>

                          </a-row>

                      </div>


                  </a-col>



                  <a-col :span="18">

                  </a-col>



                  <a-col :span="6">

                  </a-col>

              </a-row>

          </a-col>



          <a-col :span="18">

          </a-col>



          <a-col :span="6">

          </a-col>


      </a-row>
      <!---栅格布局 布局结束-->



  </div>
</template>

<script>
  import {
      receive,
      benbenSelectDiy,
      benbenFlexNumberBox,
      scrollList,
      treeSelect,
      simpleSelect,
      multipleSelect,
      cascaderForm,
      checkboxForm,
      switchForm,
      messageReply,
      rangePicker,
      handSignature,
      mixinAddTableItem,
      editorForm,
      qiunDataCharts,
      copyText,
      mixinTableItemChange,
      in_array,
      themeColorInit,
      getUrlKey,
      clearDataPass,
      isEmpty
  } from '@/assets/diypaaspc/diy-pass-pc.js'
  import {
      provinceForm
  } from '@/assets/diypaaspc/province_area.js'
  import request from '@/utils/request'
  export default {
      name: "goods",
      components: {
          qiunDataCharts,
          benbenSelectDiy,
          //            productSelect, 
          benbenFlexNumberBox,
          scrollList,
          treeSelect,
          simpleSelect,
          messageReply,
          rangePicker,
          multipleSelect,
          provinceForm,
          editorForm,
          cascaderForm,
          switchForm,
          checkboxForm,
          //            uploadImage,
          //            uploadFile,
          //            uploadMedia,
          //            uploadSingleFile,
          handSignature
      },
      data() {
          receive(this);
          const themeColorConfig = {
              "presetApi": {
                  "getClassifyOne": "",
                  "getClassifyTow": "",
                  "getClassifyShop": ""
              },
              "themeType": 0,
              "themeConfig": [{
                  "colorConfig": ["#333333", "#666666", "#999999", "#ffffff", "#1178F0", "#F02325", "#FC9C3A", "#4DC777", "#F2CC98", "#F2CC66"],
                  "bgColorConfig": ["#EBEDF8", "#FFFFFF", "#1178F0", "#FC9C3A", "#FC9fff", "#E6F333", "#E6F1FE", "linear-gradient(301deg, #EBBA7B 0%, #F8D9AD 100%)", "linear-gradient(180deg, #F5588E 0%, #F0403C 100%)", "linear-gradient(179deg, #FFD033 0%, #F4890E 100%)"],
                  "bdColorConfig": ["#EBEDF8", "#FFFFFF", "#1178F0", "#FC9C3A", "#333333", "#666666", "#999999", "#dddddd", "#1178F0", "#F02325"]
              }]
          };
          return {
              themeColorConfig,
              "fieldName_4661": [{
                  "aid": "1",
                  "name": "选项1"
              }, {
                  "aid": "2",
                  "name": "选项2"
              }],
              "fieldName_8123": [{
                  "aid": "1",
                  "name": "选项1"
              }, {
                  "aid": "2",
                  "name": "选项2"
              }]
          };

      },
      computed: {

      },
      created() {

      },
      mounted() {

      },
      beforeDestroy() {

      },
      watch: {},
      methods: {
          copyText,
          mixinTableItemChange,
          in_array,
          themeColorInit,
          isEmpty,

      }
  }
</script>

<style lang="less" scoped>
  @import 'https://zhongtai.zhongbenruanjian.com/static/diypaaspc/css/diy-pass-pc.css';

  .antdVue {
      width: 100%;
      background: #F8F8F8;
      background-size: 100% auto !important;
  }

  #antdVue .heartcheckManagementpicChecklist_flex_0 {
      background: #fff;
      width: 100%;
  }

  #antdVue .heartcheckManagementpicChecklist_fd0_1_c1 {
      background: var(--benbenbgColor3);
      margin: 0px 0px 0px 10px;
      background-size: 100% auto !important;
      color: var(--benbenFontColor3);
  }

  #antdVue .heartcheckManagementpicChecklist_fd0_1_c0 {
      background: var(--benbenbgColor2);
      background-size: 100% auto !important;
      color: var(--benbenFontColor3);
  }

  #antdVue .heartcheckManagementpicChecklist_fd0_0_c0_c1_c5_c0 {
      background: #4781f5;
      margin: 0px 10px 0px 0px;
      background-size: 100% auto !important;
      color: #fff;
  }

  #antdVue .heartcheckManagementpicChecklist_fd0_0_c0_c1 {
      background: #fff;
      width: 100%;
  }

  #antdVue .heartcheckManagementpicChecklist_fd0_0_c0_c0_c2_c0 {
      font-size: 14px;
  }

  #antdVue .heartcheckManagementpicChecklist_fd0_0_c0_c0_c0_c0 {
      font-size: 14px;
  }

  #antdVue .heartcheckManagementpicChecklist_fd0_0_c0_c0 {
      background: #fff;
      width: 100%;
  }

  #antdVue .heartcheckManagementpicChecklist_fd0_0_c0 {
      background: #fff;
      width: 100%;
      min-height: 100px;
  }

  #antdVue .heartcheckManagementpicChecklist_flex_1 {
      background: #fff;
      width: 100%;
  }

  #antdVue .heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0_c1_c0_c2_c1 {
      margin: 5px 0px 0px 0px;
  }

  #antdVue .heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0_c1_c0_c2_c0 {
      width: 32px;
      height: 32px;
      border-radius: 0px 0px 0px 0px;
  }

  #antdVue .heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0_c1_c0_c2 {
      width: 100%;
  }

  #antdVue .heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0_c1_c0_c1_c1 {
      margin: 5px 0px 0px 0px;
  }

  #antdVue .heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0_c1_c0_c1_c0 {
      width: 32px;
      height: 32px;
      border-radius: 0px 0px 0px 0px;
  }

  #antdVue .heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0_c1_c0_c1 {
      width: 100%;
  }

  #antdVue .heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0_c1_c0_c0_c1 {
      margin: 5px 0px 0px 0px;
  }

  #antdVue .heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0_c1_c0_c0_c0 {
      width: 32px;
      height: 32px;
      border-radius: 0px 0px 0px 0px;
  }

  #antdVue .heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0_c1_c0_c0 {
      width: 100%;
  }

  #antdVue .heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0_c1_c0 {
      width: 100%;
  }

  #antdVue .heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0_c0_c0_c6_c0 {
      width: 100%;
      height: 100%;
      border-radius: 0px 0px 0px 0px;
  }

  #antdVue .heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0_c0_c0_c6 {
      width: 100%;
  }

  #antdVue .heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0_c0_c0_c5_c0 {
      width: 100%;
      height: 100%;
      border-radius: 0px 0px 0px 0px;
  }

  #antdVue .heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0_c0_c0_c5 {
      width: 100%;
  }

  #antdVue .heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0_c0_c0_c4_c1 {
      margin: 5px 0px 0px 0px;
  }

  #antdVue .heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0_c0_c0_c4_c0 {
      width: 100%;
      height: 100%;
      border-radius: 0px 0px 0px 0px;
  }

  #antdVue .heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0_c0_c0_c4 {
      width: 100%;
      padding: 20px 20px 20px 20px;
  }

  #antdVue .heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0_c0_c0_c3_c0 {
      width: 100%;
      height: 100%;
      border-radius: 0px 0px 0px 0px;
  }

  #antdVue .heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0_c0_c0_c3 {
      width: 100%;
  }

  #antdVue .heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0_c0_c0_c2_c1 {
      width: 100%;
  }

  #antdVue .heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0_c0_c0_c2_c0 {
      width: 100%;
      height: 100%;
      border-radius: 5px;
      padding: 20px 20px 20px 20px;
  }

  #antdVue .heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0_c0_c0_c2 {
      width: 100%;
  }

  #antdVue .heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0_c0_c0_c1_c0 {
      border-radius: 0px 0px 0px 0px;
      width: 100%;
      height: 100%;
  }

  #antdVue .heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0_c0_c0_c1 {
      width: 100%;
  }

  #antdVue .heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0_c0_c0_c0_c1_c1 {
      background: rgba(246, 66, 18, 1);
      background-size: 100% auto !important;
      color: var(--benbenFontColor3);
  }

  #antdVue .heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0_c0_c0_c0_c1_c0 {
      background: #50AE55;
      background-size: 100% auto !important;
      color: var(--benbenFontColor3);
  }

  #antdVue .heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0_c0_c0_c0_c1 {
      width: 100%;
      box-sizing: content-box;
  }

  #antdVue .heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0_c0_c0_c0_c0 {
      width: 100%;
      height: 100%;
      border-radius: 0px 0px 0px 0px;
  }

  #antdVue .heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0_c0_c0_c0 {
      width: 100%;
      padding: 20px 20px 20px 20px;
  }

  #antdVue .heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0_c0_c0 {
      width: 100%;
  }

  #antdVue .heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0_c0 {
      margin: 10px 0px 10px 0px;
  }

  #antdVue .heartcheckManagementpicChecklist_fd1_0_c0_c0_c0_c0 {
      width: 100%;
  }

  #antdVue .heartcheckManagementpicChecklist_fd1_0_c0_c0_c0 {
      background: rgba(245, 250, 254, 1);
      width: 100%;
      background-size: 100% auto !important;
      padding: 15px 0px 25px 0px;
      border-radius: 8px 8px 8px 8px;
  }

  #antdVue .heartcheckManagementpicChecklist_fd1_0_c0 {
      background: #fff;
      width: 100%;
  }
</style>